import "./index.css"
import React, { useState, useEffect } from "react"

import { LoadingButton } from '@mui/lab';
import Checkbox from "@mui/material/Checkbox"
import { FormControlLabel } from "@mui/material"
import { useLogin } from "src/hooks/queries/useAuth"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { useLoginStore } from "src/stores/login"
import * as yup from "yup"
import { useNavigate, useLocation } from "react-router-dom"
import _ from "lodash"
import { useTranslation } from "react-i18next";
import { RoutePath } from 'src/routes/path';
import { CountryPhoneInput } from '../CountryPhoneInput';
import { getCountry } from 'src/utils/localStorage';
import { getError, getErrorMessage } from 'src/utils/common';


const formSchema = yup.object().shape({
    phone: yup.string().required('Phone number is required'),
    checked: yup.boolean().oneOf([true], 'Required'),
  });
const LoginWithPhone = ({ onNext }) => {
  const { t } = useTranslation()
  const { setFormState } = useLoginStore()
  const { mutateAsync, isLoading } = useLogin()
  const [isError, setIsError] = useState(false)
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const prePhoneNumber = searchParams.get('phone');
  const storageCountry = getCountry();

  const [country, setCountry] = useState(storageCountry);
  const navigate = useNavigate()

  //form
  const { control, register, handleSubmit, formState, watch, setValue } =
    useForm({ resolver: yupResolver(formSchema) })
  const checked = watch("checked")


  const handleSubmitForm = async ({ phone }) => {
    try {
      await mutateAsync({ phone, email: '' });
      setFormState({ phone, email: null });
      onNext();
    } catch (error) {
      const errCode = _.get(error, 'response.status', null);
      if (errCode === 404) {
        navigate(`${RoutePath.REGISTER}?phone=${phone}`);
      }
      setIsError(true);
    }
  };

  useEffect(() => {
    if (!storageCountry) {
      setTimeout(() => {
        const _country = getCountry();
        if (_country) {
          setCountry(_country);
        }
      }, 2000);
    } else {
      setCountry(storageCountry);
    }
  }, [storageCountry]);

  useEffect(() => {
    if (prePhoneNumber) {
      let _phone = prePhoneNumber;
      if (!prePhoneNumber.startsWith('+')) {
        _phone = '+' + prePhoneNumber;
      }
      setValue('phone', prePhoneNumber);
      handleSubmitForm({
        phone: _phone.replace(' ', ''),
        checked: true,
        email: '',
      });
    }
  }, [prePhoneNumber]);
  return (
    <div className="login-from__container">
      <div className="login-header">
        <p>{t('Login')}</p>
      </div>
      <div className="login__content">
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <div className="login__inner">
            <CountryPhoneInput
                country={country | ''}
                {...register("phone")}
                className="mail-input"
                placeholder={t('enterYourPhoneNumber')}
                error={getError(formState, 'phone')}
                message={getErrorMessage(formState, 'phone')}
                control={control}
              
            />
            <div className="term__content" style={{marginLeft: '1em'}}>
              <FormControlLabel
                control={
                  <Checkbox
                    className="term-checkbox"
                    {...register("checked")}
                  />
                }
                label={<></>}
              />

              <p className="description">{t('IAgreeBitmambo')} {t('TermsOfUse')}</p>
            </div>
            <div className="signin-button__content">
              <LoadingButton
                variant="contained"
                type="submit"
                disabled={!checked}
                loading={isLoading}
                className="loading-button"
              >
                {t('Login')}
              </LoadingButton>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default LoginWithPhone
