// @ts-nocheck
import axios, { AxiosRequestConfig, AxiosResponse } from "axios"
import _ from "lodash"
import { RoutePath } from "src/routes/path"
import { getToken, removeToken } from "src/utils/localStorage"
import { getEnv, envEnum } from "src/utils/env"

const request = axios.create({
  baseURL: getEnv(envEnum.BASE_URL),
  timeout: 30 * 1000,
  withCredentials: true
})

// request interceptor
request.interceptors.request.use((axiosConfig: AxiosRequestConfig) => {
  axiosConfig.headers = {
    ...axiosConfig.headers,
    Authorization: `Bearer ${getToken()}`
  }

  return axiosConfig
})

request.interceptors.response.use(
  (response: AxiosResponse) =>
    _.get(response, "data.message") || _.get(response, "data"),
  (error) => {
    const status = _.get(error, "response.status")
    if ([403, 401].includes(status)) {
      removeToken()
      // TODO re-login
      // window.location.href = RoutePath.LOGIN;
    }
    return Promise.reject(error)
  }
)

export default request
