import React, { useEffect, useMemo, useState } from 'react';
import i18n from 'i18next';
import {
  RouterProvider,
  createBrowserRouter,
  Navigate
} from 'react-router-dom';

import { RoutePath } from 'src/routes/path';
import { useGlobalStore } from 'src/stores';
import { useRefreshToken } from 'src/hooks/queries/useAuth';
import { FlashScreen } from 'src/components/Common/FlashScreen';
import { getToken } from 'src/utils/localStorage';
import ProtectedRoute from './ProtectedRoute';

import Home from "src/pages/home"
import Payment from "src/pages/payment"
import Blog from "src/pages/blog"
import BlogDetail from "src/pages/blog-detail"
import Transactions from "src/pages/transactions"
import Contact from "src/pages/contact"
import TransactionDetails from "src/pages/transactionDetails"
import Login from "src/pages/login"
import Register from 'src/pages/register';
import Profile from 'src/pages/profile'
import Referral from 'src/pages/ref';
import KYCCompleted from 'src/pages/kyc-completed';
import TransactionCompleted from 'src/pages/transaction-completed';



function AppRoutes() {
  const { isAuth } = useGlobalStore();
  const { mutateAsync } = useRefreshToken();
  const [refreshLoading, setRefreshLoading] = useState(true);
  const lang = `/${i18n.language}`;
  const isAuthenticated = useMemo(() => isAuth, [isAuth]);

  const allRoutes = [
    {
      exact: true,
      path: RoutePath.REGISTER,
      element: <Register />
    },
    {
      exact: true,
      path: RoutePath.LOGIN,
      element: <Login />,
    },
    {
      exact: true,
      path: RoutePath.PAYMENT,
      element: <Payment />,
    },
    {
      exact: true,
      path: RoutePath.HOME,
      element: <Home />,
    },
    {
      exact: true,
      path: RoutePath.BLOGS,
      element: <Blog />,
    },
    {
      exact: true,
      path: RoutePath.BLOG_DETAIL,
      element: <BlogDetail />,
    },
    {
      exact: true,
      path: RoutePath.CONTACT,
      element: <Contact />,
    },
    {
      exact: true,
      path: RoutePath.REF,
      element: <Referral />,
    },
    {
      exact: true,
      path: RoutePath.KYC_COMPLETED,
      element: <KYCCompleted />,
    },
    {
      exact: true,
      path: RoutePath.TRANSACTION_COMPLETED,
      element: <TransactionCompleted />,
    },
    {
      exact: true,
      path: RoutePath.TRANSACTION_HISTORY,
      element: (
        <ProtectedRoute isAuthenticated={isAuthenticated}>
          <Transactions />
        </ProtectedRoute>
      ),
    },
    {
      exact: true,
      path: RoutePath.TRANSACTION_DETAILS,
      element: (
        <ProtectedRoute isAuthenticated={isAuthenticated}>
          <TransactionDetails />
        </ProtectedRoute>
      )
    },
    {
      exact: true,
      path: RoutePath.PROFILE,
      element: (
        <ProtectedRoute isAuthenticated={isAuthenticated}>
          <Profile />
        </ProtectedRoute>
      )
    },
    {
      path: "/*",
      element: (
        <Navigate to={`/${lang}`} />
      )
    }
  ];

  useEffect(() => {
    // TODO PASS TOKEN IN HERE
    const handleRefresh = async () => {
      await mutateAsync();
      setRefreshLoading(false);
    };

    const token = getToken();
    if (token) {
      handleRefresh();
    } else {
      setRefreshLoading(false);
    }
  }, []);

  if (refreshLoading) {
    return <FlashScreen />;
  }


  const routers = createBrowserRouter(allRoutes, { basename: lang })


  return (
    <RouterProvider router={routers} />
  );
}

export default AppRoutes;

