import React, { useState, useEffect } from "react"
import * as yup from "yup"
import _ from "lodash"
import { useForm, useController, Controller } from "react-hook-form"
import { LoadingButton } from '@mui/lab';
import Checkbox from "@mui/material/Checkbox"
import OutlinedInput from "@mui/material/OutlinedInput"
import Grid from '@mui/material/Grid';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import { yupResolver } from '@hookform/resolvers/yup';

import './index.css'
import Box from '@mui/material/Box';
import DatePicker from "../DatePicker"
import { getError, getErrorMessage } from 'src/utils/common';
import { useGetProfile } from 'src/hooks/queries/useAuth';
import { usePayment } from 'src/hooks/queries/useCurrency';
import { useSaveCard } from 'src/hooks/queries/useCard';
import { useBuyCurrencyStore } from 'src/stores/buyCurrency';
import dayjs from 'dayjs';
import CountrySelect from "../CountrySelect"
import { useTranslation } from 'react-i18next';
import { SelectCardModal } from "./SelectCardModal";
import { TextInput } from "../Common/Inputs/TextInput";


const formSchema = yup.object().shape({
  card_holder_name: yup.string().required('Required'),
  card_number: yup.number().required('Required'),
  cvv: yup.string().required('Required'),
  country: yup.string().required('Required'),
  state: yup.string().required('Required'),
  address: yup.string().required('Required'),
  city: yup.string().required('Required'),
  zip: yup.string().required('Required'),
});

const CardPayment = () => {
  const { t } = useTranslation()
  //store
  const {
    transactionState: { id },
    onNext,
  } = useBuyCurrencyStore();


  const [focus, setFocus] = useState('');
  const [validExpiry, setValidExpiry] = useState('')
  const [isAcceptSaveCard, setIsAcceptSaveCard] = useState(false)
  const [openSelectCard, setOpenSelectCard] = useState(false)

  // form
  const { handleSubmit, register, formState, control, watch, setValue } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'all',
    defaultValues: { expiration_date: null },
  })

  // hooks
  const { data } = useGetProfile();

  const { mutateAsync: payment, isLoading } = usePayment();
  const { mutate: saveCard } = useSaveCard();

  const wHolderName = watch('card_holder_name', '')
  const wCardNumber = watch('card_number', '')
  const wCVV = watch('cvv', '')
  const wExpirationDate = watch('expiration_date', '')
  const { field } = useController({ name: 'expiration_date', control });

  const handleInputFocus = (e) => {
    setFocus(mapFocus(e.target.name))
  }
  const handleSaveCardChange = (e) => {
    setIsAcceptSaveCard(e.target.checked)
  }
  const selectSavedCard = () => {
    setOpenSelectCard(true)
  }
  const closeSelectCard = () => {
    setOpenSelectCard(false)
  }

  const handleChangeCard = ({
    card_holder_name,
    card_number,
    expiration_date,
  }) => {
    setValue('card_holder_name', card_holder_name);
    setValue('card_number', card_number);
    const _expiration_obj = dayjs(expiration_date, "MM/YYYY")
    setValue('expiration_date', _expiration_obj);
    setValue('cardSaveNumber', card_number);
    closeSelectCard()
  };

  const mapFocus = (e) => {
    switch (e) {
      case "card_holder_name":
        return "name";
      case "card_number":
        return "number"
      case "cvv":
        return "cvc"
      case "date_picker":
        return "expiry"
      default: return ''

    }
  }

  useEffect(() => {
    if (wExpirationDate && wExpirationDate.toString() != "Invalid Date") {
      const _expiration_date = dayjs(wExpirationDate).format('MM/YY')
      setValidExpiry(_expiration_date)
    }
  }, [wExpirationDate])
  useEffect(() => {
    setValue('state', _.get(data, 'state', ''));
    setValue('country', _.get(data, 'country.country_short', ''));
    setValue('address', _.get(data, 'street1', ''));
    setValue('city', _.get(data, 'city', ''));
    setValue('zip', _.get(data, 'postal_code', ''));
  }, [data]);

  const handleSubmitForm = async (values) => {
    const _expiration_date = dayjs(wExpirationDate).format('MM/YYYY')
    await payment({
      ...values,
      payment_id: id,
      payment_option: 'Card',
      card: {
        ...values.card,
        expiration_date: _expiration_date
      }
    });
    onNext()
    const {
      card_holder_name,
      card_number,
      expiration_date,
      cardSaveNumber,
    } = values;

    const isSaveCard = isAcceptSaveCard && cardSaveNumber !== card_number;

    if (isSaveCard) {
      const _expiration_date_to_save = dayjs(wExpirationDate).format('MM/YYYY')
      saveCard({
        card_holder_name,
        card_number,
        expiration_date: _expiration_date_to_save,
      });
    }
  }

  return (
    <div className="wallet__container">
      <div className="wallet-header">
        <p>{t("pm_cardPayment")}</p>
      </div>
      <div className="wallet__content">
        <div className="wallet__inner">
          <p className="description">
            {t("pm_enterCard")}
          </p>
          <SelectCardModal
            open={openSelectCard}
            onClose={closeSelectCard}
            onSelectCard={handleChangeCard} />
          <form
            style={{ width: "100%" }}
            onSubmit={handleSubmit(handleSubmitForm)}
          >
            <Cards
              cvc={wCVV}
              expiry={validExpiry}
              focused={focus}
              name={wHolderName}
              number={wCardNumber}
            />
            <a href="#" onClick={selectSavedCard}>
              <p className="action-link">Select saved cards {">>"}</p>
            </a>
            <TextInput
              className="card-input"
              error={getError(formState, 'card_number')}
              message={getErrorMessage(formState, 'card_number')}
              placeholder={t("cardNumber")}
              {...register('card_number')}
              onFocus={handleInputFocus}
            />
            <TextInput
              className="card-input"
              error={getError(formState, 'card_holder_name')}
              message={getErrorMessage(formState, 'card_holder_name')}
              placeholder={t("cardHolderName")}
              {...register('card_holder_name')}
              onFocus={handleInputFocus}
            />
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextInput
                    error={getError(formState, 'cvv')}
                    message={getErrorMessage(formState, 'cvv')}
                    className="card-input"
                    placeholder="CVV"
                    {...register('cvv')}
                    onFocus={handleInputFocus}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DatePicker
                    error={getError(formState, 'expiration_date')}
                    message={getErrorMessage(formState, 'expiration_date')}
                    className="card-input"
                    disablePast
                    {...field}
                    onFocus={handleInputFocus}
                  />
                </Grid>
              </Grid>
            </Box>
            <p className="description second-block">
              {t("pm_billingWithCard")}
            </p>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Controller
                    name='country'
                    control={control}
                    defaultValue=''
                    render={({ field }) => (
                      <CountrySelect
                        className="mail-input"
                        placeholder={t("country")}
                        {...field}
                      />
                    )}
                  />

                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    className="mail-input"
                    placeholder={t("zipcode")}
                    {...register('zip')}
                  />
                </Grid>
              </Grid>
            </Box>
            <TextInput
              className="card-input"
              placeholder={t("address")}
              {...register('address')}
            />
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextInput
                    className="card-input"
                    placeholder={t("city")}
                    {...register('city')}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    className="card-input"
                    placeholder={t("state")}
                    {...register('state')}
                  />
                </Grid>
              </Grid>
            </Box>
            <div className="term__content">
              <Checkbox className="term-checkbox" onChange={handleSaveCardChange} />
              <p className="description"> {t("pm_saveCard")}</p>
            </div>

            <div className="continue-button__content">
              <LoadingButton
                variant="contained"
                className="loading-button"
                type="submit"
                loading={isLoading}
              >
                {t("pm_submit")}
              </LoadingButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default CardPayment
