import { map } from "lodash-es"
import { MenuItem, Select } from "@mui/material"

import { getCurrencyImage } from "./content"

import "./index.css"

const CurrencySelect = ({currencies, value, onChange, ...rest }) => {
  return (
    <Select
      className="coin-select"
      value={value}
      onChange={onChange}
      displayEmpty
      inputProps={{ "aria-label": "Without label" }}
      size="small"
      {...rest}
    >
      {map(currencies, (symbol) => {
        return (
          <MenuItem key={symbol} value={symbol} className="coin__container">
            <div className="coin__content">
              <img src={getCurrencyImage(symbol)} alt={symbol} />
              <p>{symbol}</p>
            </div>
          </MenuItem>
        )
      })}
    </Select>
  )
}

export default CurrencySelect
