import { useState } from "react"



import "./index.css"
import SubmitBilling from "./SubmitBilling";
import SelectProvider from "./SelectProvider";

const MobilePayment = () => {
  const [ step, setStep]= useState(0);
  
  return (
    <>
      {step === 0 && <SubmitBilling setSubStep={setStep} />}
      {step === 1 && <SelectProvider />}
    </>
  )
}

export default MobilePayment
