import {
  MAP_CRYPTO_ICONS,
  MAP_CURRENCY_ICONS,
  MAP_NETWORK_ICONS
} from "src/constants/images"

/* eslint-disable no-unused-vars */
export type Currency = {
  id: string
  short_name: string
  long_name?: string
  decimal_precision?: number
  asset_id?: string
  networks: string[]
  price: number
}

export type Option = {
  id: string
  name: string
}

export type PairResponse = {
  [key: string]: Currency[]
}

export type SwapCurrency = {
  spend: number
  receive: number
}

export type RegisterAccount = {
  email: string
  firstName: string
  lastName: string
  dob: string
  checked: boolean
  password: string
}

export type GetFee = {
  currency?: string | null
  token?: string | null
  network?: string | null
  country?: string | null
  version?: string | null
}

export type GetFeeResponse = {
  convert_fee: {
    fee: number
    unit: string
  }
  tx_fee: {
    fee: number
    unit: string
  }
  currency: string
  token: string
  network: string
}

export type VerifyWalletAddress = {
  address: string
  network: string
}

export type VerifyWalletAddressResponse = {
  isValid: boolean
  message: string
}

export type PreparePayment = {
  from_currency: string
  to_currency: string
  network: string
  address: string
  country: string
  amount: number
}

export type PreparePaymentResponse = {
  id: string
  fees: {
    convert_fee: {
      fee: number
    }
    tx_fee: {
      fee: number
    }
  }
}

export type Payment = {
  payment_id: string
  card_holder_name: string
  card_number: string
  expiration_date: string | null
  cvv: string
  country: string
  state: string
  address: string
  city: string
  zip: string
  isSaveCard?: boolean
  cardSaveNumber?: string
  phone_number?: string
  payment_option: string
}

export type PaymentConfirm = {
  payment_id: string
  phone_number: string
  sub_option: string
  payment_option: string
  voucher?: string
}

export type PaymentResponse = {
  result: {
    transactionId: string
    redirectUrl: string
  }
  resultType: "redirect" | "success" | "error" | "options" | "info"
  options: any[]
}

export type PaymentOptionResponse = {
  options: Option[]
}

export enum TransactionStatus {
  CREATED = "CREATED",
  INIT = "INIT",
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
  DONE = "DONE",
  DECLINED = "DECLINED",
  PARTIAL = "PARTIAL",
  COMPLETED = "COMPLETED",
  ERROR = "ERROR"
}

export type TransactionHistory = {
  id: string
  created_date: string
  blockchain_explorer_url: string
  payment_details: {
    from_currency: string
    to_currency: string
    from_amount: number
    to_amount: number
    address: string
    network: string
  }
  status: TransactionStatus
}

export type TransactionHistoriesResponse = {
  docs: TransactionHistory[]
  pagination: {
    totalDocs: number
    totalPage: number
    page: number
    limit: number
  }
}

export type CurrencySymbol = keyof typeof MAP_CURRENCY_ICONS
export type CryptoSymbol = keyof typeof MAP_CRYPTO_ICONS
export type NetworkSymbol = keyof typeof MAP_NETWORK_ICONS
