import React from 'react';
import { Box, Paper, Avatar, Typography, Tabs, Tab } from '@mui/material';

import { useGetProfile } from 'src/hooks/queries/useAuth';
import { useTranslation } from 'react-i18next';
import { useSettingsStore } from 'src/stores/settings';
import "./index.css"
import { getFullName } from 'src/utils/string';
import { useQuery } from 'src/hooks/app/useQuery';
import { useGetKycStatus } from 'src/hooks/queries/useKyc';
import KYCStatus from "./KYCStatus";
import Infomation from "./Information"
import KycVerification from "./KycVerification"
import { useNavigate } from "react-router-dom"
import { RoutePath } from 'src/routes/path';


const Profile = () => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const { data } = useGetProfile();
    const { kyc } = useSettingsStore();
    const query = useQuery();
    const tab = query.get('tab') || 'infomation';
    const { data: kycStatus } = useGetKycStatus();
    const [value, setValue] = React.useState(tab);

    const handleChange = (_event, newValue) => {
        setValue(newValue);
        navigate(`${RoutePath.PROFILE}?tab=${newValue}`);
    };

    return (
        <div className="home-banner__container">
            <div className="home-banner__content">
                <div className="home-banner__inner">
                    <div className="left-vector" />
                    <div className="right-vector" />
                    <Paper
                        sx={{
                            p: {
                                base: 5,
                                xs: 5,
                                sm: 5,
                                md: 5,
                                lg: 5,
                            },
                            borderRadius: '20px',
                        }}
                    >
                        <Box display='flex' flexDirection='column' alignItems='center'>
                            <Box mb={2}>
                                <Avatar sx={{ width: 120, height: 120 }} />
                            </Box>
                            <Box textAlign='center'>
                                <Typography>{getFullName(data)}</Typography>
                                <Typography variant='subtitle1'>{data?.email}</Typography>
                                {kyc.enabled == true && (
                                    <KYCStatus status={_.get(kycStatus, 'status', false)} />
                                )}
                            </Box>
                        </Box>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: "10px" }}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                aria-label='basic tabs example'
                                centered
                            >
                                <Tab label={t('information')} value='infomation' />
                                {/* {kyc.enabled == true && <Tab label='KYC' value='kyc' />} */}
                            </Tabs>
                        </Box>
                        {tab === 'infomation' && <Infomation />}
                        {/* {tab === 'kyc' && <KycVerification />} */}
                    </Paper>
                </div>
            </div>
        </div>
    );
};

export default Profile;

