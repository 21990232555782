export const CURRENCY = [
  {
    name: "USDT",
    symbol: "USDT",
    url: "/icons/coin/usdt.svg"
  },
  {
    name: "EUR",
    symbol: "EUR",
    url: "/icons/coin/eur.svg"
  },
  {
    name: "XOF",
    symbol: "XOF",
    url: "/icons/coin/xof.svg"
  },
  {
    name: "KES",
    symbol: "KES",
    url: "/icons/coin/kes.svg"
  },
  {
    name: "GHN",
    symbol: "GHN",
    url: "/icons/coin/ghn.svg"
  },
  {
    name: "GNF",
    symbol: "GNF",
    url: "/icons/coin/gnf.svg"
  }
]

export const getCurrencyImage = (symbol) => {
  for (let c of CURRENCY) {
    if (c.symbol === symbol) {
      return c.url
    }
  }
}
