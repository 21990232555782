import React from 'react';
import { Navigate } from 'react-router-dom';

import { RoutePath } from 'src/routes/path';

const ProtectedRoute = (props) => {
  const { children, isAuthenticated, ...rest } = props;
  if (!isAuthenticated) {
    return <Navigate to={RoutePath.HOME} />
  }
  return children;
};

export default React.memo(ProtectedRoute);
