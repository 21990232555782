import React from 'react';
import {
    FormControl,
    FormHelperText,
    FormLabel,
    OutlinedInput,
} from '@mui/material';


export const TextInput = React.forwardRef(
    (props, ref) => {
        const { placeholder, message, error, ...rest } = props;
        return (
            <FormControl fullWidth>
                {/* {label && <FormLabel error={error}>{label}</FormLabel>} */}
                <OutlinedInput error={error} ref={ref} placeholder={placeholder} {...rest} />
                {error && message && (
                    <FormHelperText error={error}>{message}</FormHelperText>
                )}
            </FormControl>
        );
    }
);

