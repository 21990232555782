import { useNavigate } from "react-router-dom"

import "./index.css"

const BlogBox = ({ data }) => {
  const { title, description, type, url } = data
  const navigate = useNavigate()

  return (
    <div
      className="blog-box__container"
      onClick={() => navigate("/blog-detail")}
    >
      <div className="blog-box__inner">
        <div className="blog-box-header">
          <img src={url} alt="blog" />
        </div>
        <div className="blog-box__content">
          <div>
            <p className="type">{type}</p>
            <p className="title">{title}</p>
            <p className="description">{description}</p>
          </div>
          <a href="/detail" className="read-more">
            READ MORE
          </a>
        </div>
      </div>
    </div>
  )
}

export default BlogBox
