import React from "react"
import ReactOTPInput, { OtpInputProps } from "react-otp-input"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  input: {
    width: "50px !important",
    height: "50px !important",
    fontWeight: "bold",
    fontFamily: theme.typography.fontFamily,
    "&:focus": {
      outlineColor: "#1976d2"
    },
    marginTop: '15px'
  }
}))

const VerificationCodeInput = (props) => {
  const classes = useStyles()

  return (
    <ReactOTPInput
      {...props}
      numInputs={6}
      separator={<span style={{ width: "4px" }} />}
      inputStyle={classes.input}
    />
  )
}
export default VerificationCodeInput
