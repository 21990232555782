import "./index.css"
import React, { useState } from "react"
import LoginWithEmail from "./LoginWithEmail"
import VerifyLoginOtp from "src/components/VerifyLoginOtp"
import { useSettingsStore } from 'src/stores/settings';
import LoginWithPhone from "./LoginWithPhone";

const LoginForm = ({redirect}) => {
  const [step, setStep] = useState(1)
  const { authenticationMethod } = useSettingsStore();
  
  const onNext = () => {
    setStep(step + 1)
  }
  
  return (
    <>
      {(step === 1 && authenticationMethod === 'email') && <LoginWithEmail onNext={onNext}/>}
      {(step === 1 && authenticationMethod === 'phone') && <LoginWithPhone onNext={onNext}/>}
      {step === 2 && <VerifyLoginOtp redirect={redirect}/>}
    </>
  )
}

export default LoginForm
