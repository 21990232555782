import { map } from "lodash-es"

import "./index.css"
const SOCIAL_DATA = [
  {
    name: "facebook",
    url: "/images/social/facebook.svg"
  },
  {
    name: "twitter",
    url: "/images/social/twitter.svg"
  }
]
const FollowUs = ({ rest }) => {
  return (
    <div className="follow-us" {...rest}>
      <div className="header">
        <p>FOLLOW US</p>
      </div>
      <div>
        <div className="follow__content">
          {map(SOCIAL_DATA, ({ name, url }, idx) => (
            <img src={url} alt={name} key={idx} />
          ))}
        </div>
      </div>
    </div>
  )
}
export default FollowUs
