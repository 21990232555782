import React, { useEffect, useState } from 'react';

import Checkbox from "@mui/material/Checkbox"
import MailIcon from "@mui/icons-material/Mail"
import OutlinedInput from "@mui/material/OutlinedInput"
import InputAdornment from "@mui/material/InputAdornment"
import {  FormControlLabel } from "@mui/material"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import * as yup from "yup"
import { useNavigate, useLocation } from "react-router-dom"
import _ from "lodash"
import Box from '@mui/material/Box';
import { useCreateAccount } from 'src/hooks/queries/useAuth';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { RoutePath } from 'src/routes/path';
import { getCountry } from 'src/utils/localStorage';
import { CountryPhoneInput } from '../CountryPhoneInput';
import { LoadingButton } from '@mui/lab';
import { getError, getErrorMessage } from 'src/utils/common';
import { t } from 'i18next';



const formSchema = yup.object().shape({
    email: yup.string().email('Email is invalid'),
    first_name: yup.string().required('Required'),
    last_name: yup.string().required('Required'),
    phone: yup
      .string()
      .nullable()
      .required('Required')
      .test('phone', 'Invalid phone number', (phone) =>
        isValidPhoneNumber(phone || '')
      ),
    checked: yup.boolean().oneOf([true], 'Required'),
  });
const RegisterWithPhone = () => {
    const navigate = useNavigate();
    const search = useLocation().search;
    const searchParams = new URLSearchParams(search);
    const prefillPhone = searchParams.get('phone');
    const refId = searchParams.get('refId');
    const storageCountry = getCountry();

    const [country, setCountry] = useState(storageCountry);

    // store
    const { register, handleSubmit, formState, control, setValue, watch } =
      useForm({
        resolver: yupResolver(formSchema),
        defaultValues: {
            type: 'ID',
            phone: '',
        },
        mode: 'all',
      });
  
    const checked = watch('checked');
  
    // form and hooks
    const { mutate, isLoading } = useCreateAccount();
    const handleSubmitForm = async (values) => {
      mutate(
        { ...values, ref_id: refId },
        {
          onSuccess: () => {
            setTimeout(() => {
                navigate(`/${RoutePath.LOGIN}?phone=${values.phone}`);
            }, 1000);
          },
        }
      );
    };
  
    useEffect(() => {
        if (prefillPhone) {
          let _phone = prefillPhone.trim();
          const CountryCode = getCountryCode(_phone);
          setCountry(CountryCode || '');
          if (!prefillPhone.startsWith('+')) {
            _phone = ('+' + prefillPhone).replace(/\s/g, '');
          }
    
          setValue('phone', _phone);
        }
    }, [prefillPhone]);
    
    useEffect(() => {
        if (storageCountry) {
          setCountry(storageCountry);
        }
    }, [storageCountry]);
    
  return (
    <div className="login-from__container">
      <div className="login-header">
        <p>{t('Register')}</p>
      </div>
      <div className="login__content">
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <div className="login__inner">
            <CountryPhoneInput
                country={country | ''}
                {...register("phone")}
                className="mail-input"
                placeholder={t('enterYourPhoneNumber')}
                error={getError(formState, 'phone')}
                message={getErrorMessage(formState, 'phone')}
                control={control}
              
            />
            <Box display='flex' gap={2} sx={{width: '100%'}}>
                <OutlinedInput
                {...register("first_name")}
                className="mail-input"
                placeholder={t('enterYourFirstName')}
                
                />
                <OutlinedInput
                {...register("last_name")}
                className="mail-input"
                placeholder={t('enterYourLastName')}
               
                />
            </Box>
            <div className="term__content" style={{marginLeft: '1em'}}>
              <FormControlLabel
                control={
                  <Checkbox
                    className="term-checkbox"
                    {...register("checked")}
                  />
                }
                label={<></>}
              />

              <p className="description">{t('IAgreeBitmambo')} {t('TermsOfUse')}</p>
            </div>
            <div className="signin-button__content">
              <LoadingButton
                variant="contained"
                className="loading-button"
                type="submit"
                disabled={!checked}
                loading={isLoading}
              >
                {t('Register')}
              </LoadingButton>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default RegisterWithPhone
