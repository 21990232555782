import { map } from "lodash-es"

import { LASTED_BLOG_DATA } from "./content"

import "./index.css"

const LastestBlog = ({ rest }) => {
  return (
    <div className="lasted-blog" {...rest}>
      <div className="header">
        <p>LATEST BLOGS</p>
      </div>
      <div>
        <div className="post">
          {map(LASTED_BLOG_DATA, ({ title, url }, idx) => (
            <div className="post__content" key={idx}>
              <img src={url} alt={title} />
              <p className="title">{title}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
export default LastestBlog
