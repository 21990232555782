import { map } from "lodash-es"
import { Link } from "@mui/material"
import { useTranslation  } from "react-i18next"

import { footerContent } from "./content"
import SubFooter from "../../components/SubFooter"

import "./index.css"

const Footer = () => {
  const { t } = useTranslation()
  return (
    <div className="footer__container">
      <div className="footer__content">
        <SubFooter />
        <div className="footer-content">
          <div className="left-content__container">
            <p className="title">{t('ft_about')}</p>
            <p className="description">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
              dictum aliquet accumsan porta lectus riFdiculus in mattis. Netus
              sodales in volutpat ullamcorper amet adipiscing fermentum.
            </p>
          </div>
          <div className="right-content__container">
            {map(footerContent, ({ title, detail }, idx) => (
              <div className="right-content" key={idx}>
                <p className="title">{title}</p>
                <div className="content-link-wrapper">
                  {map(detail, ({ link, title }, idx) => (
                    <Link
                      className="content-link"
                      underline="hover"
                      href={link}
                      key={idx}
                    >
                      {title}
                    </Link>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="copyright-content">
          <div className="line" />
          <p className="no-copyright">
            Copyright © 2023 Bitmambo. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Footer
