import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

const KYCCompleted = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignContent: 'center',
                marginTop: 10,
            }}
        >
            <CircularProgress sx={{ alignSelf: 'center' }} />
            <Typography sx={{ alignSelf: 'center', marginTop: 3 }} variant='h5'>
                Confirming your KYC.
            </Typography>
        </Box>
    );
};

export default KYCCompleted;

