import React from "react"
import _ from "lodash"
import {Box, Stack, CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useForm, Controller} from "react-hook-form"
import Button from "@mui/material/Button"
import OutlinedInput from "@mui/material/OutlinedInput"
import Grid from '@mui/material/Grid';
import 'react-credit-cards/es/styles-compiled.css';

import './index.css'
import { usePayment } from 'src/hooks/queries/useCurrency';
import { useBuyCurrencyStore } from 'src/stores/buyCurrency';
import CountrySelect from "../CountrySelect"
import { useTranslation } from 'react-i18next';


const SubmitBilling = ({setSubStep}) => {
  const { t } = useTranslation()
  const flashSubmitting = false
    //store
  const {
        transactionState: { id },
  } = useBuyCurrencyStore();
  
  // hooks
  const { mutateAsync: payment, isLoading } = usePayment();
  
  //form
  const { handleSubmit, register, formState, control, watch, setValue} = useForm()
  const handleSubmitForm = async (values) => {
    await payment({
      ...values,
      phone_number: '0',
      payment_id: id,
      payment_option: 'Mobile',
    });
    setSubStep(1);
  }
  if (flashSubmitting) {
    return (
      <div className="wallet__container">
      <div className="wallet-header">
        <p>{t("MobilePayment")}</p>
      </div>
      <div className="wallet__content">
        <div className="wallet__inner">
        <Stack spacing={2} mt={4}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        </Stack>
          
        </div>
      </div>
    </div>
    );
  } else
  return (
    <div className="wallet__container">
      <div className="wallet-header">
        <p>{t("MobilePayment")}</p>
      </div>
      <div className="wallet__content">
        <div className="wallet__inner">
          
          <form
            style={{ width: "100%" }}
            onSubmit={handleSubmit(handleSubmitForm)}
          >
            <p className="description second-block">
              {t("pm_billingWithPhone")}
            </p>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                    <Controller
                      name='country'
                      control={control}
                      defaultValue=''
                      render={({ field }) => (
                        <CountrySelect
                            className="mail-input"
                            placeholder={t("country")}
                            {...field}
                        />
                      )}
                    />
                    
                    </Grid>
                    <Grid item xs={6}>
                    <OutlinedInput
                        className="mail-input"
                        placeholder={t("zipcode")}
                        {...register('zip')}
                    />
                    </Grid>
                </Grid>
            </Box>
            <OutlinedInput
              className="card-input"
              placeholder={t("address")}
              {...register('address')}
            />
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                    <OutlinedInput
                        className="card-input"
                        placeholder={t("city")}
                        {...register('city')}
                    />
                    </Grid>
                    <Grid item xs={6}>
                    <OutlinedInput
                        className="card-input"
                        placeholder={t("state")}
                        {...register('state')}
                    />
                    </Grid>
                </Grid>
            </Box>
            
            <div className="continue-button__content">
              <LoadingButton
                variant="contained"
                className="loading-button"
                type="submit"
                loading={isLoading}
              >
                {t("Continue")}
              </LoadingButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default SubmitBilling
