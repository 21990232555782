import { Button, Link } from "@mui/material"
import { useNavigate } from "react-router-dom"

import DropdownMenu from "../../../components/Dropdown"
import { useGlobalStore } from 'src/stores';
import SelectLanguage from "src/components/SelectLanguage";
import i18n from 'i18next';
import { useTranslation } from "react-i18next";

import "./index.css"
import UserAvatar from "src/components/UserAvatar";
import { useBuyCurrencyStore } from "src/stores/buyCurrency";

const NavMenu = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { isAuth } = useGlobalStore();
  const { setStep } = useBuyCurrencyStore();

  const handleLogin = () => {
    navigate(`/login`)
  }

  const langOnChange = (e) => {
    const url = `${window.location.protocol}//${window.location.hostname}:${window.location.port
      }/${e.toLowerCase()}`;
    window.location.replace(url);
  }

  const handleRegister = () => {
    navigate(`/register`)
  }
  const navigateHome = () => {
    const lang = i18n.language
    const url = `${window.location.protocol}//${window.location.hostname}:${window.location.port
      }/${lang}`;
    window.location.replace(url);
  }

  return (
    <div className="nav-menu__container">
      <div className="menu-group-button">
        <Link underline="hover" href="#" onClick={navigateHome}>
          {t('Home')}
        </Link>
        <Link underline="hover" href={`/${i18n.language}/blogs`}>
          {t('blogs')}
        </Link>
        {isAuth && (
          <Link underline="hover" href={`/${i18n.language}/transactions`}>
            {t('transactions')}
          </Link>
        )}

        {/* <DropdownMenu title="English" />
        <DropdownMenu title="Tools" /> */}
      </div>
      <div className="authen-group-button">
        {!isAuth && (
          <>
            <Button
              variant="text"
              onClick={handleRegister}>
              {t('Register')}
            </Button>
            <Button
              variant="outlined"
              onClick={handleLogin}
              className={"signin-button"}
            >
              {t('Login')}
            </Button>
          </>
        )}
        {isAuth && (
          <UserAvatar />
        )}
        <SelectLanguage onChange={langOnChange} />
      </div>

    </div>
  )
}

export default NavMenu
