import withLayout from "../../hoc/withLayout"
import TransactionHistories from 'src/components/TransactionHistories'
import "./index.css"

import { useTranslation } from "react-i18next"

const Transactions = () => {
  const { t } = useTranslation()

  return (
    <div className="transaction__container">
      <div className="transaction__inner">
        <div className="title__content">
          <p className="title">{t('transactions')}</p>
          <div className="break" />
        </div>
        <div className="transaction-form__content">
            <TransactionHistories />
        </div>
      </div>
    </div>
  )
}

export default withLayout(Transactions)
