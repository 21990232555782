import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import {
  Avatar,
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  MenuList,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import _ from 'lodash';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { supportedLanguages } from 'src/lang/i18n';
import { FR, EN, SV } from 'src/constants/images';
import { useSettingsStore } from 'src/stores/settings';
import { getCountry } from 'src/utils/localStorage';
import { useTranslation } from 'react-i18next';

const icons = {
  fr: FR,
  en: EN,
  sv: SV,
};

// type Props = {
//   onChange: (event: any) => void;
// };

const SelectLanguage = ({ onChange }) => {
  const [open, setOpen] = useState(false);
  const { i18n, t } = useTranslation();

  let lang = i18n.language;
  const country = getCountry();
  const {
    languagesState: { defaultLanguage, languages },
  } = useSettingsStore();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (value) => {
    onChange(value);
    handleClose();
  };

  return (
    <>
      <Chip
        label={lang}
        onClick={handleOpen}
        onDelete={() => undefined}
        deleteIcon={<ChevronRightOutlinedIcon />}
        avatar={<Avatar src={_.get(icons, `${lang}`)} />}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth
        maxWidth='xs'
        PaperProps={{ sx: { borderRadius: 4 } }}
      >
        <DialogTitle id='alert-dialog-title'>
          <Typography fontWeight='bold'>{t('SelectLanguage')}</Typography>
          <IconButton
            aria-label='close'
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              border: '1px solid',
              borderColor: 'divider',
              borderRadius: 2,
              maxHeight: '420px',
              overflow: 'auto',
              mt: 2,
            }}
          >
            <MenuList>
              {supportedLanguages.map((language) => (
                <MenuItem key={language} onClick={() => handleChange(language)}>
                  <Box display='flex'>
                    <Avatar src={_.get(icons, language)} />
                    <Box ml={2}>
                      <Typography
                        variant='button'
                        display='block'
                        lineHeight={1.2}
                      >
                        {language}
                      </Typography>
                      <Typography variant='caption' lineHeight={1}>
                        {language}
                      </Typography>
                    </Box>
                  </Box>
                </MenuItem>
              ))}
            </MenuList>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SelectLanguage;

