import * as React from 'react';
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    Divider,
    Button,
    Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useGetCards, useRemoveCard } from 'src/hooks/queries/useCard';
import IconButton from '@mui/material/IconButton';
import "./index.css"

export const SelectCardModal = ({
    onClose,
    onSelectCard,
    ...props
}) => {

    const { data: cards, refetch } = useGetCards();
    const { mutateAsync, isLoading } = useRemoveCard();
    const deleteCard = async (card_number) => {
        await mutateAsync({ card_number });
        refetch()
    }

    const handleSelectCard = (cardNumber) => {
        const card = cards.find(c => c.card_number === cardNumber)
        onSelectCard(card)
    }

    return (
        <Dialog
            maxWidth='sm'
            PaperProps={{ sx: { borderRadius: '20px' } }}
            {...props}
        >
            <DialogTitle variant='button' className="dialog_title">
                Select Card
                <IconButton onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ width: '420px' }}>

                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        {cards?.map(card => (
                            <Grid container spacing={2} sx={{ marginTop: 1, marginLeft: 1 }} key={card.card_number}>
                                <Grid item xs={10} >
                                    <Button
                                        variant="outlined"
                                        fullWidth
                                        onClick={() => handleSelectCard(card.card_number)}
                                    >
                                        {card.card_number}
                                    </Button>
                                </Grid>
                                <Grid item xs={2}>
                                    <IconButton onClick={() => deleteCard(card.card_number)} sx={{ color: 'red' }}>
                                        <DeleteOutlineIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        ))}

                    </Grid>
                </Box>
            </DialogContent>
        </Dialog >
    );
};
