import React, { useState } from "react"
import _ from "lodash"
import Button from "@mui/material/Button"

import MobilePayment from "../MobilePayment"
import CardPayment from "../CardPayment"
import BankTransfer from "../BankTransfer"
import { MASTER_CARD, CELL_PHONE, BANK } from 'src/constants/images';
import { useTranslation } from 'react-i18next';
import { useSettingsStore } from 'src/stores/settings';

const BtnMethod = ({ url, onClick, id, selected }) => {
  const btnColor = selected === id ? 'rgba(0, 0, 0, 0.25)' : ''
  return (
    <Button
      sx={{ backgroundColor: btnColor }}
      onClick={() => onClick(id)}>
      <img key={'discover'} className="payment-branch" src={url} alt={'discover'} />
    </Button>
  )
}

const DisplayOptions = ({ setSubStep, setPaymentMethod }) => {
  const { t } = useTranslation()
  const [selectedOption, setSelectedOption] = useState(0)
  const { paymentMethods } = useSettingsStore();

  const handleSelectOption = (e) => {
    const option = paymentOptions.options.find(
      (op) => op.id === e
    );
    setPaymentMethod(option.name)
    setSubStep(1)
  }

  const mapIcon = (name) => {
    switch (name) {
      case 'Bank Transfer':
        return BANK;
      case 'Mobile':
        return CELL_PHONE;
      case 'Card':
        return MASTER_CARD;
      default:
        return '';
    }
  };

  if (paymentMethods && paymentMethods.length == 1) {
    switch (paymentMethods[0]) {
      case "Card":
        return <CardPayment />
      case "Bank Transfer":
        return <BankTransfer />
      case "Mobile":
        return <MobilePayment />
      default:
        return null
    }
  }
  else {
    return (
      <div className="wallet__container">
        <div className="wallet-header">
          <p>{t("pm_paymentMethod")}</p>
        </div>
        <div className="wallet__content">
          <div className="wallet__inner">
            <p className="description">{t("pm_select")}</p>
            <div className="images-container">
              {_.map(paymentMethods, val => (
                <BtnMethod
                  url={mapIcon(val)}
                  id={val}
                  onClick={handleSelectOption} selected={selectedOption}
                  key={val}
                />)
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default DisplayOptions
