import React, { useState } from "react"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import {
  DesktopDatePicker,
  DesktopDatePickerProps
} from "@mui/x-date-pickers/DesktopDatePicker"
import { FormControl, FormLabel } from "@mui/material"
import OutlinedInput from "@mui/material/OutlinedInput"

const DatePicker = React.forwardRef(
  (
    {
      label,
      error,
      message,
      format = 'MM/YYYY',
      views = ['month', 'year'],
      onFocus,
      onBlur,
      ...rest
    }, ref) => {
    const [isFocus, setIsFocus] = useState(false);
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          {...rest}
          views={views}
          inputFormat={format}
          InputProps={{ onBlur }}
          ref={ref}

          renderInput={({ error: inputError, ...params }) => (
            <FormControl error={error} fullWidth>
              <OutlinedInput
                required
                {...params}
                onFocus={onFocus}
                error={error}
                helperText={error && message}
                label={null}
                name="date_picker"
              />
            </FormControl>
          )}
        />
      </LocalizationProvider>
    );
  });

export default DatePicker;