import React from "react"
import { toast } from "react-toastify"
import _ from "lodash"

import {
  ToastMessageProps,
  ToastMessage
} from "src/components/Common/ToastMessage"

// Use this without component
export const toastSuccess = (props: ToastMessageProps) =>
  toast(<ToastMessage {...props} type="success" />, { hideProgressBar: true })
export const toastError = (props: ToastMessageProps) =>
  toast(<ToastMessage {...props} type="error" />, { hideProgressBar: true })
export const toastInfo = (props: ToastMessageProps) =>
  toast(<ToastMessage {...props} type="info" />, { hideProgressBar: true })
export const toastWarning = (props: ToastMessageProps) =>
  toast(<ToastMessage {...props} type="warning" />, { hideProgressBar: true })

// Handle error when call api
export const handleError = (errors: any) => {
  let messageErrors = _.get(errors, "response.data.errors", "")
  if (typeof messageErrors === "string" || messageErrors instanceof String) {
    if (messageErrors) {
      toastError({ description: messageErrors as string })
      return
    }
  }
  if (_.isArray(messageErrors)) {
    toastWarning({ description: messageErrors.join(", ") })
    return
  }

  const message = _.get(errors, "message")
  toastError({ description: message })
}

export const handleWarning = (errors: any) => {
  let messageErrors = _.get(errors, "response.data.errors")
  if (typeof messageErrors === "string" || messageErrors instanceof String) {
    if (messageErrors) {
      toastWarning({ description: messageErrors as string })
      return
    }
  }
  if (_.isArray(messageErrors)) {
    toastWarning({ description: messageErrors.join(", ") })
    return
  }

  const message = _.get(errors, "message")
  toastWarning({ description: message })
}
