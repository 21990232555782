import CardPayment from "../CardPayment"
import BankTransfer from "../BankTransfer"
import MobilePayment from "../MobilePayment"

const RedirectOption = ({paymentMethod}) => {
    switch(paymentMethod) {
        case "Card":
            return <CardPayment/>
        case "Bank Transfer":
            return <BankTransfer/>
        case "Mobile":
            return <MobilePayment />
        default:
            return null
    }
}

export default RedirectOption