import Button from "@mui/material/Button"
import { useTranslation } from 'react-i18next';
import { useBuyCurrencyStore } from 'src/stores/buyCurrency';
import { useNavigate } from "react-router-dom"
import { RoutePath } from "src/routes/path";

import "./index.css"

const PaymentStatus = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  // stores
  const {
    transactionState: { id },
    resetStore
  } = useBuyCurrencyStore();

  const goDetail = () => {
    resetStore()
    navigate(`${RoutePath.TRANSACTION_HISTORY}/${id}`)
  }

  return (
    <div className="wallet__container">
      <div className="wallet-header">
        <p>{t('paymentCompleted')}</p>
      </div>
      <div className="wallet__content">
        <div className="wallet__inner">
          <div className="transaction-completed">
            <p>{t('completed')}</p>
          </div>
          <div className="transaction-instruction">
            <p>{t('paymentCompleted_visitDetail')}</p>
          </div>
          <div className="transaction-instruction">
              <Button
                variant="contained"
                className="loading-button"
                onClick={goDetail}
              >
                {t('transactionDetails')}
              </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentStatus