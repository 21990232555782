import { useEffect } from "react"

import withLayout from "../../hoc/withLayout"
import LoginForm from "../../components/LoginForm"
import WalletForm from "../../components/WalletForm"
import ConvertForm from "../../components/ConvertForm"
import PaymentOptions from "../../components/PaymentOptions"
import PaymentStatus from "../../components/PaymentStatus"
import { useBuyCurrencyStore } from 'src/stores/buyCurrency';
import { useGetProfile } from "../../hooks/queries/useAuth"

import "./index.css"

const Payment = () => {
  const { step, setStep } = useBuyCurrencyStore();
  const { isSuccess } = useGetProfile();
  useEffect(() => {
    if(step === 1 && isSuccess) {
      setStep(2)
    }
  },[isSuccess])
  return (
    <div className="authentication__container">
      <div className="authentication__inner">
        <ConvertForm />
        {step === 1 && <LoginForm redirect={false}/>}
        {step === 2 && <WalletForm />}
        {step === 3 && <PaymentOptions />}
        {step === 4 && <PaymentStatus />}
      </div>
    </div>
  )
}

export default withLayout(Payment)
