import _ from "lodash"
import { User } from "src/Types/Auth"

export const shortAddress = (value: string) =>
  `${value.slice(0, 12)}...${value.slice(value.length - 4, value.length)}`

export const getFullName = (values?: Partial<User>) =>
  `${_.get(values, "first_name", "")} ${_.get(values, "last_name", "")}`.trim()

export const shortEmail = (email: string) => {
  if (!email) return ""
  const domain = _.last(email.split("@"))
  const maskedEmail = _.replace(email, /^(.{4}).*/, `$1******@${domain}`)
  return maskedEmail
}

export const parseTokenName = (tokenName: string, network: string) => {
  if (network == "TRON") {
    return `${tokenName} (TRC20)`
  }
  return tokenName
}

export const formatCurrency = (amount: string | number, currency: string) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency
  })
  return formatter.format(Number(amount))
}
