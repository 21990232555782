import { useState } from "react"
import { IconButton } from "@mui/material"
import { useNavigate } from "react-router-dom"
import MenuIcon from "@mui/icons-material/Menu"

import NavMenu from "./NavMenu"
import MobileMenu from "./MobileMenu"
import Logo from "../../components/Logo"
import i18n from 'i18next';

import "./index.css"

const Header = () => {
  const navigate = useNavigate()
  const [isOpenDrawer, setDisplayDrawer] = useState(false)

  const navigateHome = () => {
    const lang = i18n.language
    const url = `${window.location.protocol}//${window.location.hostname}:${window.location.port
      }/${lang}`;
    window.location.replace(url);
  }

  return (
    <div className="header-wrapper">
      <div className="content">
        <Logo className="logo" onClick={navigateHome} />
        <NavMenu />
        <MobileMenu isOpen={isOpenDrawer} setOpen={setDisplayDrawer} />
        <div className="mobile-menu">
          <IconButton
            color="inherit"
            aria-label="menu"
            className="menu-button"
            onClick={() => setDisplayDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
        </div>
      </div>
    </div>
  )
}
export default Header
