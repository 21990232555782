import { DataGrid } from '@mui/x-data-grid';
import _ from 'lodash';
import { IconButton, Typography } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { useGetTransactionHistories } from 'src/hooks/queries/useCurrency';
import { useTransactionHistoryPage } from 'src/stores/transactionHistory';
import { useNavigate } from "react-router-dom"

import { formatDateTime } from 'src/utils/date';
import { formatPrice } from 'src/utils/number';
import { shortAddress } from 'src/utils/string';
import { colors } from 'src/constants/transactionStatus';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

  
const LIMIT = 10;
const Status = ({ status }) => {
    const { t } = useTranslation()
    return (
      <Typography variant='button' color={colors[status]}>
        {t(status)}
      </Typography>
    );
  };
const Transactions = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [ page, setPage]  = useState(1);
    const { data: transactionHistories, isLoading } = useGetTransactionHistories({
        page,
        limit: LIMIT,
    });

    const columns = [
        { field: 'id', headerName: t('transactionid'), width: 300 },
        { field: 'address', headerName: t('address'), width: 300, renderCell: (param) => {
            return (<>{shortAddress(param.row.address)}</>)
        }},
        { field: 'network', headerName: t('network'), width: 100 },
        { field: 'fiat_amount', headerName: t('tran_his_tb_fiat_price'), width: 200, renderCell: (param) => {
            return (<>{formatPrice(param.row.fiat_amount)}</>)
        }},
        { field: 'crypto_amount', headerName: t('tran_his_tb_crypto_price'), width: 200, renderCell: (param) => {
            return (<>{formatPrice(param.row.crypto_amount)}</>)
        }},
        { field: 'updated_date', headerName: t('date'), width: 200, renderCell: (param) => {
            return (<>{formatDateTime(param.row.updated_date)}</>)
        }},
        { field: 'status', headerName: t('status'), width: 150, renderCell: (param) => {
            return (<Status status={param.row.status}/>)
        }},
        { field: 'blockchain_explorer_url', headerName: 'BlockScan', width: 100, renderCell: (params) => {
            const blockchain_explorer_url = params.row.blockchain_explorer_url;
            return (
                <IconButton
                    disabled={!blockchain_explorer_url}
                    onClick={() =>
                    window.open(blockchain_explorer_url, '_blank')
                    }
                >
                    <LinkIcon />
                </IconButton>
            );
          },
        },
        { field: 'detail', headerName: t('details'), width: 100, renderCell: (params) => {
            const id = params.row.id;
            return (
                <IconButton onClick={() => {navigate(`/transactions/${id}`)}}>
                    <VisibilityIcon />
              </IconButton>
            );
          },
        },
        
    ];

    const handleChangePage = (value) => {
        console.log('value: ', value)
        setPage(value);
    };
    const getRows = () => {
        const items = _.get(transactionHistories, 'items', []);
        let rowItems = []
        for (const item of items) {
            rowItems.push({
                ...item,
                network: _.get(item, 'payment_details.network'),
                fiat_amount: _.get(item, 'payment_details.from_amount'),
                crypto_amount: _.get(item, 'payment_details.to_amount'),
                address: _.get(item, 'payment_details.address'),
            })
        }
        return rowItems
    }
    return (
        <DataGrid
            sx={{width: '90%'}}
            rows={getRows()}
            columns={columns}
            pagination
            pageSizeOptions={[10]}
            page={page}
            onPageChange={(newPage) => handleChangePage(newPage)}
            loading={isLoading}
            pageSize={10}
            rowCount={_.get(transactionHistories, 'total', 0)}
            paginationMode="server"
        />
    )
}

export default Transactions
