import Avatar from "@mui/material/Avatar"
import { TextareaAutosize } from "@mui/base/TextareaAutosize"
import Button from "@mui/material/Button"

import "./index.css"

const AddComment = ({ rest }) => {
  return (
    <div className="comment" {...rest}>
      <Avatar alt="Nana" src="/static/images/avatar/1.jpg" />
      <div className="comment__content">
        <TextareaAutosize
          className="text-area"
          minRows={5}
          maxRows={7}
          placeholder="Add a comment...."
        />
        <Button variant="contained" className="send-button">
          SEND
        </Button>
      </div>
    </div>
  )
}
export default AddComment
