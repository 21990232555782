import { useState } from "react"

import DisplayOptions from "./DisplayOptions";
import RedirectOption from "./RedirectOption";

const PaymentOptions = () => {
  const [ step, setStep]= useState(0);
  const [paymentMethod, setPaymentMethod] = useState('')
  
  return (
    <>
      {step === 0 && <DisplayOptions setSubStep={setStep} setPaymentMethod={setPaymentMethod}/>}
      {step === 1 && <RedirectOption paymentMethod={paymentMethod} />}
    </>
  )
}

export default PaymentOptions
