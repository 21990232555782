import React, { useState } from "react"
import PhoneInput from "react-phone-number-input/react-hook-form"
import "react-phone-number-input/style.css"
import { makeStyles } from "@mui/styles"
import theme from "../../config/theme"

import { FormControl, FormHelperText, FormLabel, Theme } from "@mui/material"
const useStyles = makeStyles(() => ({
  input: {
    
    "& .PhoneInput": {
      border: `1px solid ${theme.palette.action.disabled}`,
      borderRadius: "5px",
      height: '40px',
      backgroundColor: 'white',
      paddingLeft: '10px',
      "& input": {
        border: "none !important",
        outline: "none !important",
        backgroundColor: "transparent",
        color: theme.palette.text.primary,
        fontFamily: theme.typography.fontFamily,
        fontSize: '12px'
      },

      "&:hover": {
        borderColor: theme.palette.text.primary
      },

      "&:focus-within": {
        borderColor: theme.palette.primary.main
      }
    }
  },
  error: {
    "& .PhoneInput": {
      borderColor: `${theme.palette.error.main} !important`
    }
  }
}))

// type Props = {
//   name: string
//   error?: boolean
//   message?: string
//   label?: string
//   control?: any
//   country: string
// }

export const CountryPhoneInput = React.forwardRef(
  ({ label, name, error, message, control, country }) => {
    const [isFocus, setIsFocus] = useState(false)
    const classes = useStyles()

    return (
      <FormControl
        fullWidth
        error={error}
        focused={isFocus}
        className={`${classes.input} ${error ? classes.error : ""}`}
        sx={{ border: "none !important" }}
      >
        {label && <FormLabel error={error}>{label}</FormLabel>}
        <PhoneInput
          international
          countryCallingCodeEditable={false}
          defaultCountry={country  | ""}
          control={control}
          name={name}
          onFocus={() => setIsFocus(true)}
          onBlur={() => setIsFocus(false)}
        />
        {error && message && (
          <FormHelperText error={error}>{message}</FormHelperText>
        )}
      </FormControl>
    )
  }
)
