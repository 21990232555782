import { QueryClient, QueryClientProvider } from "react-query"
import { ToastContainer } from "react-toastify"
import AppRoutes from "src/routes"
import GlobalProvider from "./providers/GlobalProvider"

import "./App.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "react-toastify/dist/ReactToastify.css"
import "./utils/ensure-basename"

const queryClient = new QueryClient()

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <GlobalProvider>
        <ToastContainer />
        <AppRoutes />
      </GlobalProvider>
    </QueryClientProvider>
  )
}

export default App
