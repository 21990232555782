import React, { useEffect } from 'react';
import { ThemeProvider } from "@emotion/react"

import { useGlobalStore } from 'src/stores';
import { useMutateGetCountry } from 'src/hooks/queries/useCommon';
import { useMutateGetSettings } from 'src/hooks/queries/useSettings';
import { useSettingsStore } from 'src/stores/settings';
// import NoServiceScreen from 'src/screens/NoService';
import { getCountry } from 'src/utils/localStorage';
import theme from "../config/theme"


const GlobalProvider = ({ children }) => {
  const { mode } = useGlobalStore();
  const { mutate: fetchCountry } = useMutateGetCountry();
  const { mutate: fetchSettings } = useMutateGetSettings();
  const {
    activeState: { enabled },
  } = useSettingsStore();

  useEffect(() => {
    fetchSettings();
    if (!getCountry()) {
      fetchCountry();
    }
  }, []);
  if (enabled)
    return (
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    );
  else {
    return (
      <ThemeProvider theme={theme}>
        {/* <NoServiceScreen /> */}
        {/* TODO: no service screen */}
      </ThemeProvider>
    );
  }
};

export default GlobalProvider;

