import { map, times } from "lodash-es"

import "./index.css"

const TagBox = ({ rest }) => {
  return (
    <div className="tag-box" {...rest}>
      <div className="header">
        <p>TAGS</p>
      </div>
      <div>
        <div className="tag-box__content">
          {map(times(12), (_, idx) => (
            <div className="tag" key={idx}></div>
          ))}
        </div>
      </div>
    </div>
  )
}
export default TagBox
