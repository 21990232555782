import { create } from "zustand"

export interface ActiveState {
  enabled: boolean
}

type Language = {
  country: string
  language: string
}

export type LanguagesSettingsState = {
  defaultLanguage: string
  languages: Language[]
}

type supportedCountriesState = {
  countries: string[]
}
type KYC = {
  enabled: boolean
  amount: number
}

export type SettingsState = {
  activeState: ActiveState
  languagesState: LanguagesSettingsState
  supportedCountriesState: supportedCountriesState
  authenticationMethod: string | null
  kyc: KYC
  paymentMethods: string[]
  paymentMethodsLogos: string[]
  setActiveState: (values: ActiveState) => void
  setLanguagesState: (values: LanguagesSettingsState) => void
  setSupportedCountriesState: (values: supportedCountriesState) => void
  setAuthenticationMethod: (value: string) => void
  setKYC: (values: KYC) => void
  setPaymentMethods: (values: string[]) => void
  setPaymentMethodsLogos: (values: string[]) => void
}

const initialState: SettingsState = {
  activeState: {
    enabled: true
  },
  languagesState: {
    defaultLanguage: "fr",
    languages: []
  },
  supportedCountriesState: {
    countries: []
  },
  authenticationMethod: null,
  kyc: {
    enabled: false,
    amount: 0
  },
  paymentMethods: [],
  paymentMethodsLogos: [],

  setActiveState: () => null,
  setLanguagesState: () => null,
  setSupportedCountriesState: () => null,
  setAuthenticationMethod: () => null,
  setKYC: () => null,
  setPaymentMethods: () => null,
  setPaymentMethodsLogos: () => null
}

export const useSettingsStore = create<SettingsState>((set) => ({
  ...initialState,
  setActiveState: (values: ActiveState) =>
    set(({ activeState }) => ({
      activeState: { ...activeState, ...values }
    })),
  setLanguagesState: (values: LanguagesSettingsState) =>
    set(({ languagesState }) => ({
      languagesState: { ...languagesState, ...values }
    })),
  setSupportedCountriesState: (values: supportedCountriesState) =>
    set(({ supportedCountriesState }) => ({
      supportedCountriesState: { ...supportedCountriesState, ...values }
    })),
  setAuthenticationMethod: (value: string) =>
    set(() => ({
      authenticationMethod: value
    })),
  setKYC: (values: KYC) =>
    set(() => ({
      kyc: { ...values }
    })),
  setPaymentMethods: (values: string[]) =>
    set(() => ({
      paymentMethods: values
    })),
  setPaymentMethodsLogos: (values: string[]) =>
    set(() => ({
      paymentMethodsLogos: values
    }))
}))
