import { useQuery, useMutation } from "react-query"
import { getSettings } from "src/apis/settings"
import _ from "lodash"
import { useSettingsStore } from "src/stores/settings"
import { useBuyCurrencyStore } from "src/stores/buyCurrency"

export const useMutateGetSettings = () => {
  const { setSessionState } = useBuyCurrencyStore()
  const {
    setActiveState,
    setLanguagesState,
    setSupportedCountriesState,
    setAuthenticationMethod,
    setKYC,
    setPaymentMethods,
    setPaymentMethodsLogos
  } = useSettingsStore()
  return useMutation(getSettings, {
    onSuccess: (data) => {
      setAuthenticationMethod(data.login_method)
      setActiveState({ enabled: data.enabled })
      setLanguagesState({
        defaultLanguage: _.get(data, "default_language", "fr"),
        languages: _.get(data, "languages", [])
      })
      setSupportedCountriesState({ countries: _.get(data, "countries", []) })
      setSessionState({ firstPay: true })
      setKYC(data.kyc)
      setPaymentMethods(data.payment_methods_by_country)
      setPaymentMethodsLogos(data.payment_methods_logos)
    }
  })
}
