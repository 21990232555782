import "./index.css"
import React, { useState, useEffect } from "react"

import { LoadingButton } from '@mui/lab';
import Checkbox from "@mui/material/Checkbox"
import MailIcon from "@mui/icons-material/Mail"
import OutlinedInput from "@mui/material/OutlinedInput"
import InputAdornment from "@mui/material/InputAdornment"
import { FormGroup, FormControlLabel } from "@mui/material"
import { useLogin } from "src/hooks/queries/useAuth"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { useLoginStore } from "src/stores/login"
import * as yup from "yup"
import { useNavigate, useLocation } from "react-router-dom"
import _ from "lodash"
import { useTranslation } from "react-i18next";

const formSchema = yup.object().shape({
  email: yup.string().email("Email is invalid").required("Required"),
  checked: yup.boolean().oneOf([true], "Required")
})
const LoginWithEmail = ({ onNext }) => {
  const { t } = useTranslation()
  const { setFormState } = useLoginStore()
  const { mutateAsync, isLoading } = useLogin()
  const [isError, setIsError] = useState(false)
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const preEmail = searchParams.get('email');

  const navigate = useNavigate()
  const { register, handleSubmit, formState, watch } =
    useForm({ resolver: yupResolver(formSchema) })
  const checked = watch("checked")

  const handleSubmitForm = async ({ email }) => {
    try {
      await mutateAsync({ email, phone: "" })
      setFormState({ email, phone: "" })
      onNext()
    } catch (error) {
      const errCode = _.get(error, "response.status", null)
      if (errCode === 404 || errCode === 400) {
        navigate(`/register?email=${email}`)
      }
      setIsError(true)
    }
  }
  useEffect(() => {
    if (preEmail) {
      let _email = preEmail.trim();

      handleSubmitForm({
        email: _email.replace(' ', ''),
        checked: true,
        phone: '',
      });
    }
  }, [preEmail]);
  return (
    <div className="login-from__container">
      <div className="login-header">
        <p>{t('Login')}</p>
      </div>
      <div className="login__content">
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <div className="login__inner">
            <OutlinedInput
              {...register("email")}
              className="mail-input"
              placeholder="Enter your email..."
              startAdornment={
                <InputAdornment position="start">
                  <MailIcon />
                </InputAdornment>
              }
            />
            <div className="term__content" style={{ marginLeft: '1em' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    className="term-checkbox"
                    {...register("checked")}
                  />
                }
                label={<></>}
              />

              <p className="description">{t('IAgreeBitmambo')} {t('TermsOfUse')}</p>
            </div>
            <div className="signin-button__content">
              <LoadingButton
                variant="contained"
                type="submit"
                disabled={!checked}
                loading={isLoading}
                className="loading-button"
              >
                {t('Login')}
              </LoadingButton>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default LoginWithEmail
