import AddComment from "../../components/AddComment"
import FollowUs from "../../components/FollowBox"
import LastestBlog from "../../components/LastestBlog"
import SearchBox from "../../components/SearchBox"
import TagBox from "../../components/TagBox"
import withLayout from "../../hoc/withLayout"
import { BLOG_DETAIL_DATA } from "./content"

import "./index.css"

const BlogDetail = () => {
  const { title, author, content, background } = BLOG_DETAIL_DATA

  return (
    <div className="blog-detail">
      <div className="blog-detail__container ">
        <div className="title__content">
          <p className="title">{title}</p>
          <p className="author">{author}</p>
        </div>
        <div className="blog-detail__content">
          <img className="banner" src={background} alt="blog-banner" />
          <div className="left-vector" />
          <div className="detail">
            <div className="blog__element">
              <div className="blog-element">
                <p className="date">25 October 2023</p>
                <p className="text-content">{content}</p>
              </div>
              <AddComment />
            </div>
            <div className="suggestion">
              <SearchBox />
              <LastestBlog />
              <FollowUs />
              <TagBox />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withLayout(BlogDetail)
