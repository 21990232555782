import React, { useEffect } from 'react';
import {
    Box, CircularProgress,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';

const Referral = () => {
    const navigate = useNavigate();
    const params = useParams();
    const id = _.get(params, 'id', '');
    useEffect(() => {
        if (id) {
            navigate(`/register?refId=${id}`)
        }
    }, [])
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
            <CircularProgress />
        </Box>
    )
};

export default Referral;
