import React from "react"
import Button from "@mui/material/Button"
import { LoadingButton } from '@mui/lab';
import "./index.css"
import { useState } from "react"
import { useBuyCurrencyStore } from "src/stores/buyCurrency"
import OutlinedInput from "@mui/material/OutlinedInput"
import { useForm } from 'react-hook-form';
import { usePaymentConfirm } from 'src/hooks/queries/useCurrency';
import { useTranslation } from 'react-i18next';

const BtnProvider = ({url, onClick, id, selected}) => {
  const btnColor = selected === id ? 'rgba(0, 0, 0, 0.25)' : ''
  return (
      <Button
        sx={{backgroundColor: btnColor}}
        onClick={() => onClick(id)}>
        <img key={'discover'} className="payment-branch" src={url} alt={'discover'}/>
      </Button>
    )
}

const SelectProvider = () => {
  const { t } = useTranslation()
  const [selectedOption, setSelectedOption] = useState(0)
  const [formInputs, setFormInputs] = React.useState([]);
  const { transactionState: {
    subOptions,
    id,
    onNext,
  }} = useBuyCurrencyStore()

  // hooks
  const { mutate: confirm, isLoading } = usePaymentConfirm();


  //form
  const {
    register,
    handleSubmit,
    setValue,
  } = useForm();
  // functions
  const handleSelectOption = (id) => {
    setSelectedOption(id)
    const subOption = subOptions?.find((item) => item.id === id);
    setFormInputs(_.get(subOption, 'formInputs', []));
    setValue('sub_option', id);
  }
  const handleSubmitForm = (values) => {
    confirm(
        {
          ...values,
          phone_number: values.phone,
          payment_id: id,
          payment_option: 'Mobile',
        },
        {
          onSuccess: (data) => {
            setTransactionState({
              instructions: {
                data: _.get(data, 'result.paymentDetails[0].value', ''),
              },
            });
            onNext();
          },
          
        }
      );
  }
  return (
    <div className="wallet__container">
      <div className="wallet-header">
        <p>{t("pm_paymentMethod")}</p>
      </div>
      <div className="wallet__content">
        <form onSubmit={handleSubmit(handleSubmitForm)}>
            <div className="wallet__inner">
            <p className="description">{t("pm_select")}</p>
            <div className="images-container">
                {_.map(subOptions, vals => (
                    <BtnProvider
                        url={_.get(vals, 'logo')}
                        id={_.get(vals, 'id')}
                        onClick={handleSelectOption} selected={selectedOption}
                        key={_.get(vals, 'id')}
                    />)
                )}
            </div>
                {formInputs?.map((item) => {
                if (item.name == 'phone') {
                    return (
                        <OutlinedInput
                            className="card-input"
                            placeholder="Phone number"
                            {...register('phone')}
                        />
                    );
                }
                if (item.name == 'voucher') {
                    return (
                        <OutlinedInput
                            className="card-input"
                            placeholder="Voucher"
                            {...register('voucher')}
                        />
                    );
                }
                })}
                
                
            <div className="continue-button__content">
                <LoadingButton
                variant="contained"
                className="loading-button"
                type="submit"
                loading={isLoading}
                >
                {t("Continue")}
                </LoadingButton>
            </div>
            </div>
        </form>
      </div>
    </div>
  )
}

export default SelectProvider