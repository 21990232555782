import _ from 'lodash';
import { Chip, Stack, Box, Typography, Grid} from '@mui/material';


import { formatDateTime } from 'src/utils/date';
import { formatPrice } from 'src/utils/number';
import { shortAddress, parseTokenName } from 'src/utils/string';
import { colors } from 'src/constants/transactionStatus';
import { useGetTransactionDetails } from 'src/hooks/queries/useCurrency';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './index.css'
  
const LIMIT = 10;
const Item = ({ label, value }) => {
    return (
      <Box display='flex' mt={2}>
        <Typography variant='body1' color='text.secondary'>
          {label}:
        </Typography>
        <Typography variant='body1' color='text.primary' fontWeight={'bold'}>
          &nbsp;&nbsp;
          {value}
        </Typography>
      </Box>
    );
  };
const StatusChip = ({ status }) => {
    const { t } = useTranslation()
    const color = colors[status]?.replace('.main', '');
    return <Chip color={color} label={t(status)} />;
  };
const TransactionDetails = () => {
    const { t } = useTranslation()
    const params = useParams();
    const id = _.get(params, 'id', '');
    const { data } = useGetTransactionDetails(id);
  
    return (
        <div>
            <Stack
                direction='row'
                spacing={1}
                display='flex'
                alignItems='center'
                justifyContent='center'
            >
                <StatusChip status={data?.status} />
            </Stack>
            <div className="transaction_container">
                
                <Grid container spacing={2}>
                <Grid item xs={6} md={6} sm={12}>
                    <Typography variant='h6' color='text.primary'>
                    {t('from')}
                    </Typography>
                </Grid>
                <Grid item xs={6} md={6} sm={12}>
                    <Typography variant='h6' color='text.primary'>
                    {t('to')}
                    </Typography>
                </Grid>
                <Grid item xs={6} md={6} sm={12}>
                    <Item
                    label={t('currency')}
                    value={_.get(data, 'payment_details.from_currency', 'N/A')}
                    />
                    <Item
                    label={t('amount')}
                    value={_.get(data, 'payment_details.from_amount', 'N/A')}
                    />
                    {_.get(data, 'payment_details.mobile', null) !== 'null' && (
                    <>
                        <Item
                        label={t('phoneNumber')}
                        value={_.get(
                            data,
                            'payment_details.mobile.phone_number',
                            'N/A'
                        )}
                        />
                        <Item
                        label={t('voucher')}
                        value={_.get(
                            data,
                            'payment_details.mobile.voucher',
                            'N/A'
                        )}
                        />
                    </>
                    )}
                </Grid>
                <Grid item xs={6} md={6} sm={12}>
                    <Item
                    label={t('token')}
                    value={parseTokenName(
                        _.get(data, 'payment_details.to_currency', 'N/A'),
                        _.get(data, 'payment_details.network', 'N/A')
                    )}
                    />
                    <Item
                    label={t('amount')}
                    value={_.get(data, 'payment_details.to_amount', 'N/A')}
                    />
                    <Item
                    label={t('network')}
                    value={_.get(data, 'payment_details.network', 'N/A')}
                    />
                    <Item
                    label={t('address')}
                    value={_.get(data, 'payment_details.address', 'N/A')}
                    />
                </Grid>
                <Grid item xs={12} md={12} sm={12} mt={2}>
                    <Typography variant='h6' color='text.primary'>
                    {t('fees')}
                    </Typography>
                </Grid>
                <Grid item xs={6} md={6} sm={6}>
                    <Item
                    label={t('networkFee')}
                    value={`${_.get(data, 'fees.tx_fee.fee', 'N/A')} ${_.get(
                        data,
                        'fees.tx_fee.unit',
                        'N/A'
                    )}`}
                    />
                    <Item
                    label={t('serviceFee')}
                    value={`${formatPrice(_.get(data, 'fees.convert_fee.fee', 0)/100 * _.get(data, 'payment_details.from_amount', 0))} ${_.get(
                        data,
                        'payment_details.from_currency',
                        'N/A'
                    )}`}
                    />
                </Grid>
                <Grid item xs={6} md={6} sm={6}>
                    <Item
                    label={t('createdDate')}
                    value={`${formatDateTime(_.get(data, 'updated_date', 'N/A'))}`}/>
                </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default TransactionDetails
