import BuildBanner from "../../components/BuildBanner"
import FAQ from "../../components/FAQ"
import HomeBanner from "../../components/HomeBanner"
import PaymentMethod from "../../components/Payment"
import SeamlessProcess from "../../components/Seamless"
import Testimonial from "../../components/Testimonial"
import withLayout from "../../hoc/withLayout"

import "./index.css"

const Home = () => {
  return (
    <div className="home__container">
      <h1>The iframe element</h1>
      <iframe src="https://cashier-v2.dev.kasha.tech/pay/?sessionId=6cf3f5e5-ed4e-484f-bbba-c95264214acb" title="First iframe" width="1800" height="800" >
      </iframe>
    </div>
  )
}

export default withLayout(Home)
