import { Language } from "@mui/icons-material"

export const enCommon = {
  transactionHistory: "Transaction History",
  transactionid: "Transaction ID",
  address: "Address",
  home: "Home",
  BuyCrypto: "Buy Crypto",
  YouSpend: "You spend",
  YouReceive: "You Recieve",
  YouWillReceive: "You will recieve",
  SelectCurrency: "Select Currency",
  SelectCrypto: "Select Crypto",
  Continue: "Continue",
  WelcomeToBitmambo: "Welcome to bitmambo",
  EnterYourEmail: "Enter your email...",
  ClickHereToRegisterABitmamboAccountNow:
    "Click here to register a Bitmambo account now",
  IAgreeBitmambo: "I agree Bitmambo",
  TermsOfUse: "Terms of Use",
  ByEnteringOTPYouAgreeWithTermsAndPrivacyPolicy:
    "By entering OTP you agree with terms & privacy policy",
  WalletAddress: "Wallet Address",
  Wallet: "Wallet",
  Register: "Register",
  Login: "Login",
  Newsletter: "Newsletter",
  Company: "Company",
  Support: "Support",
  Request: "Request",
  FAQ: "FAQ",
  AboutUs: "About Us",
  WeAcceptFollowingPaymentSystems: "We accept following payment systems",
  YourRegistrationHasBeenSuccessful: "Your registration has been successful",
  YouArePaying: "You are paying",
  ForThePriceOf: "for the amount of",
  Your: "Your",
  WillBeSentTo: "will be sent to",
  RemainingTime: "Remaining time",
  PaymentHasExpired: "Please try again",
  ReviewBillingAddress: "Review Billing address",
  MobilePayment: "Mobile Payment",
  PaymentDetails: "Payment details",
  phoneNumber: "Phone number",
  email: "email",
  firstName: "first name",
  lastName: "last name",
  identifyType: "identify type",
  identifyNumber: "identify number",
  RegisterAccount: "Register Account",
  selectCountry: "Select Country",
  description: "Description",
  requestSupport: "Request Support",
  enterYourFirstName: "Enter your first name...",
  enterYourLastName: "Enter your last name...",
  dayOfBirth: "Date of birth",
  enterYourPhoneNumber: "Enter your phone number...",
  country: "Country",
  city: "City",
  state: "State",
  zipcode: "Zipcode",
  street: "Street",
  identityType: "Identity Type",
  identityNumber: "Identity Number",
  update: "Update",
  information: "information",
  YourProfileHasBeenUpdated: "Your profile has been updated",
  from: "From",
  to: "Receive",
  currency: "Currency",
  amount: "Amount",
  voucher: "Voucher",
  fees: "Fees",
  serviceFee: "Service Fee",
  networkFee: "Network Fee",
  token: "Token",
  network: "Network",
  ERROR: "Error",
  INIT: "Init",
  CREATED: "Created",
  PENDING: "Pending",
  PROCESSING: "Processing",
  COMPLETED: "Completed",
  DONE: "Done",
  remainingTime: "Remaining time",
  payment: "Payment",
  instructions: "Instructions",
  paymentSuccess: "Payment Success",
  plsSelectSubOption: "Please select sub option",
  incorrectAddress: "Incorrect address",
  transactionFee: "Transaction Fee",
  spTooLow: "The amount is too low",
  selectPaymentOption: "Select Payment Option",
  cardPayment: "Card Payment",
  billingAddress: "Billing address associated with this card",
  saveCard: "Save my card for future payments",
  cardNotice:
    "Your card will be saved securely on your account. We will only ask you to provide your CVV for your future payments.",
  bankTransfer: "Bank Transfer",
  fetchingPaymentOption: "Fetching payment options",
  waitingForPayment: "Awaiting payment confirmation",
  kycNotify: "The user is not verified. Please verify with basic verification.",
  SelectLanguage: "Select Language",
  yourCard: "Your Card",
  cardHolderName: "Card holder name",
  cardNumber: "Card number",
  expirationDate: "expiration date",
  removeThisCard: "Remove this card",
  d_instantBuy:
    "Instantly Buy USDT with Your Credit or Debit Card at Bitmambo!",
  d_instantTransaction: "Instant Transaction",
  d_support: "Support",
  d_multiPaymentOptions: "Multiple Payment Options",
  d_gateToUSDT:
    "Your gateway to USDT, facilitating instant purchases with top-tier security Enjoy world- class commission rates!",
  d_payHoweverULike: "Pay however you like",
  d_homeBannerDes: `Making BTC Investment secure and quick | Important news about latest
  development in crypto world | This is another headline giving
  information about digital currencies. | Making BTC Investment secure
  and quick | Important news about latest development in crypto world |
  This another headline giving information about digital currencies.`,
  d_SEAMLESSPROCESS: "SEAMLESS PROCESS",
  d_howToPurchase: "How to purchase Crypto from Bitmambo",
  d_samlessDescription: `Embark on a seamless journey to the world of digital currencies with
  Bitmambo! Follow these straightforward steps to acquire your
  preferred cryptocurrency:`,
  d_specifyPurchaseAmount: "Specify Your Purchase Amount",
  d_enterAmount: "Enter desired amount with live calculator",
  d_completeProfileVer: "Complete Your Profile Verification",
  d_enterWallet: "Input Your Crypto Wallet Address",
  d_payAndReceiveInstantly: "Pay and receive crypto instantly",
  d_buildYourPortfolio: "Build your portfolio",
  buyNow: "Buy Now",
  pay: "Pay",
  get: "Get",
  d_PAYMENTMETHOD: "PAYMENT METHODS",
  faq_cryptocurrencies: "What cryptocurrencies can I purchase on Bitmambo?",
  faq_paymentMethods: "What payment methods does Bitmambo accept?",
  faq_isSafe: "Is it safe to use Bitmambo?",
  faq_verifyAccount: "How do I verify my account?",
  faq_fee: "What are the fees associated with trading on Bitmambo?",
  faq_transactionTime: "How long does it take to process a transaction?",
  faq_res_paymentMethods:
    "Bitmambo supports card payment, mobile payment and bank transfer",
  faq_res_fee:
    "yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.",
  ft_jumpStart: "Jump start your crypto portfolio",
  ft_jumpStartDes1:
    "Bitmambo is the easiest place to buy and sell cryptocurrency.",
  ft_jumpStartDes2: "Sign up and get started today.",
  ft_about: "About Bitmambo",
  getStarted: "Get Started",
  purchaseNow: "Purchase Now",
  blogs: "Blogs",
  transactions: "Transactions",
  signIn: "Sign in",
  blogs_description:
    "Some important stories that you should know from the world of cryptocurrency.",
  tran_his_tb_fiat_price: "Fiat Price",
  tran_his_tb_crypto_price: "Crypto Price",
  date: "Date",
  status: "Status",
  details: "Details",
  createdDate: "Created Date",
  transactionDetails: "Transaction Details",
  paymentCompleted: "Payment Completed",
  paymentCompleted_visitDetail:
    "Your payment has been successful, Kindly visit transaction details page for more information",
  completed: "Completed",
  pm_YOUPAY: "YOU PAY",
  pm_YOUGET: "YOU GET",
  pm_feeDescription: "is all you pay, fees included",
  pm_bFee: "Bitmambo fee",
  pm_otherFee: "Other Charges",
  pm_totalFee: "Total fees",
  btn_show: "Show",
  btn_hide: "Hide",
  noti_kycError: "KYC must be verified",
  wl_enterYour: "Enter your",
  wl_wAddress: "wallet address*",
  wl_placeholder_enterWallet: "Enter Wallet Address",
  pm_paymentMethod: "Payment Method",
  pm_select: "Select payment method*",
  pm_cardPayment: "Card Payment",
  pm_enterCard: "Enter your card information*",
  pm_billingWithCard: "Billing address associated with this card*",
  pm_saveCard: "Save my card for future payments",
  pm_submit: "Submit Payment",
  pm_submitting: "Submitting Payment",
  pm_billingWithPhone: "Billing address associated with this phone number*",
  nt_spendMustBeGta: "Spend must be greater than",
  nt_andSml: "and smaller than"
}

export const frCommon = {
  transactionHistory: "Historique des transactions",
  transactionid: "Transaction ID",
  address: "Adresse",
  home: "Accueil",
  BuyCrypto: "Acheter des Crypto",
  YouSpend: "tu dépenses",
  YouReceive: "tu reçois",
  YouWillReceive: "Vous allez recevoir",
  SelectCurrency: "Sélectionnez la devise",
  SelectCrypto: "Sélectionner la cryptomonnaie",
  Continue: "Continuer",
  WelcomeToBitmambo: "Bienvenue sur bitmambo",
  EnterYourEmail: "Entrer votre Email...",
  ClickHereToRegisterABitmamboAccountNow:
    "Cliquez ici pour enregistrer un compte Bitmambo maintenant",
  IAgreeBitmambo: "J'accepte les",
  TermsOfUse: "conditions d'utilisation de Bitmambo",
  ByEnteringOTPYouAgreeWithTermsAndPrivacyPolicy:
    "En entrant OTP, vous acceptez les termes et la politique de confidentialité",
  WalletAddress: "adresse de portefeuille",
  Wallet: "Portefeuille",
  Register: "Registre",
  Login: "Connexion",
  Newsletter: "Bulletin",
  Company: "Entreprise",
  Support: "Soutien",
  Request: "Demande",
  FAQ: "FAQ",
  AboutUs: "À propos de nous",
  WeAcceptFollowingPaymentSystems:
    "Nous acceptons les systèmes de paiement suivants",
  YourRegistrationHasBeenSuccessful: "Votre inscription a réussi",
  YouArePaying: "vous payez",
  ForThePriceOf: "pour le montant de",
  Your: "Votre",
  WillBeSentTo: "era envoyé à",
  PaymentHasExpired: "Veuillez réessayer",
  RemainingTime: "Temps restant",
  ReviewBillingAddress: "Vérifier l'adresse de facturation",
  MobilePayment: "Paiement mobile",
  PaymentDetails: "Détails de paiemen",
  phoneNumber: "Numéro de téléphone",
  email: "e-mail",
  firstName: "prénom",
  lastName: "nom de famille",
  identifyType: "identifier le type",
  identifyNumber: "identifier le numéro",
  RegisterAccount: "Créer un compte",
  selectCountry: "Choisissez le pays",
  description: "Description",
  requestSupport: "Demander de l'aide",
  enterYourFirstName: "Entrez votre prénom...",
  enterYourLastName: "Entrez votre nom de famille...",
  dayOfBirth: "Date de naissance",
  enterYourPhoneNumber: "Entrez votre numéro de téléphone...",
  country: "Pays",
  city: "Ville",
  state: "Etat",
  zipcode: "Code postal",
  street: "Rue",
  identityType: "Type d identité",
  identityNumber: "Numéro d identité",
  update: "Mettre à jour",
  information: "information",
  YourProfileHasBeenUpdated: "Votre profil a été mis à jour",
  from: "De la devise",
  to: "Sera reçu",
  currency: "Devise",
  amount: "Montant",
  voucher: "Coupon",
  fees: "Frais",
  serviceFee: "Frais de service",
  networkFee: "Frais de réseau",
  token: "Crypto-monnaie",
  network: "Réseau",
  ERROR: "Erreur",
  INIT: "Initialiser",
  CREATED: "Créé",
  PENDING: "En attente",
  PROCESSING: "En cours de traitement",
  COMPLETED: "Terminé",
  DONE: "Terminé",
  remainingTime: "Temps restant",
  payment: "Paiement",
  instructions: "Instructions",
  paymentSuccess: "Paiement réussi",
  plsSelectSubOption: "Veuillez sélectionner une sous-option",
  incorrectAddress: "Adresse incorrecte",
  transactionFee: "Frais de transaction",
  spTooLow: "Le montant est trop faible",
  selectPaymentOption: "Sélectionnez l'option de paiement",
  cardPayment: "Paiement par carte",
  billingAddress: "Adresse de facturation associée à cette carte",
  saveCard: "Sauvegarder ma carte pour de futurs paiements",
  cardNotice:
    "Votre carte sera enregistrée en toute sécurité sur votre compte. Nous vous demanderons uniquement de fournir votre CVV pour vos futurs paiements.",
  bankTransfer: "Virement",
  fetchingPaymentOption: "Récupérer les options de paiement",
  waitingForPayment: "En attente de confirmation de paiement",
  kycNotify:
    "L'utilisateur n'est pas vérifié. Veuillez vérifier avec une vérification de base.",
  SelectLanguage: "Choisir la langue",
  yourCard: "Votre carte",
  cardHolderName: "Nom du titulaire",
  cardNumber: "numéro de carte",
  expirationDate: "date d'expiration",
  removeThisCard: "retirer cette carte",
  d_instantBuy:
    "Achetez instantanément des USDT avec votre carte de crédit ou de débit chez Bitmambo !",
  d_instantTransaction: "Transaction instantanée",
  d_support: "Soutien",
  d_multiPaymentOptions: "Plusieurs options de paiement",
  d_gateToUSDT:
    "Votre passerelle vers l'USDT, facilitant les achats instantanés avec une sécurité de haut niveau. Profitez de taux de commission de classe mondiale !",
  d_payHoweverULike: "Payez comme bon vous semble",
  d_homeBannerDes: `Rendre l'investissement BTC sécurisé et rapide | Nouvelles importantes concernant les dernières
  développement dans le monde de la cryptographie | Ceci est un autre titre donnant
  informations sur les monnaies numériques. | Sécuriser les investissements BTC
  et rapide | Nouvelles importantes sur les derniers développements dans le monde de la cryptographie |
  Ceci est un autre titre donnant des informations sur les monnaies numériques.`,
  d_SEAMLESSPROCESS: "PROCESSUS SANS COUTURE",
  d_howToPurchase: "Comment acheter des cryptos sur Bitmambo",
  d_samlessDescription: `Embarquez pour un voyage fluide dans le monde des monnaies numériques avec
  Bitmambo! Suivez ces étapes simples pour acquérir votre
  crypto-monnaie préférée :`,
  d_specifyPurchaseAmount: "Précisez le montant de votre achat",
  d_enterAmount: "Entrez le montant souhaité avec la calculatrice en direct",
  d_completeProfileVer: "Complétez la vérification de votre profil",
  d_enterWallet: "Saisissez l'adresse de votre portefeuille crypto",
  d_payAndReceiveInstantly: "Payez et recevez des cryptos instantanément",
  d_buildYourPortfolio: "Construisez votre portfolio",
  buyNow: "Acheter",
  pay: "Payer",
  get: "Obtenir",
  d_PAYMENTMETHOD: "MÉTHODES DE PAIEMENT",
  faq_cryptocurrencies: "Quelles cryptomonnaies puis-je acheter sur Bitmambo ?",
  faq_paymentMethods:
    "Quels sont les modes de paiement acceptés par Bitmambo ?",
  faq_isSafe: "Est-il sûr d'utiliser Bitmambo ?",
  faq_verifyAccount: "Comment vérifier mon compte ?",
  faq_fee: "Quels sont les frais associés au trading sur Bitmambo ?",
  faq_transactionTime:
    "Combien de temps faut-il pour traiter une transaction ?",
  faq_res_paymentMethods:
    "Bitmambo prend en charge le paiement par carte, le paiement mobile et le virement bancaire",
  faq_res_fee:
    "Oui, vous pouvez nous essayer gratuitement pendant 30 jours. Si vous le souhaitez, nous vous fournirons un appel d'intégration gratuit et personnalisé de 30 minutes pour vous mettre en route le plus rapidement possible.",
  ft_jumpStart: "Démarrez votre portefeuille de cryptomonnaie",
  ft_jumpStartDes1:
    "Bitmambo est l'endroit le plus facile pour acheter et vendre des cryptomonnaies.",
  ft_jumpStartDes2: "Inscrivez-vous et commencez dès aujourd'hui.",
  ft_about: "À propos de Bitmambo",
  getStarted: "Commencer",
  purchaseNow: "Acheter maintenant",
  blogs: "Blogs",
  transactions: "Transactions",
  signIn: "Se connecter",
  blogs_description:
    "Certaines histoires importantes que vous devriez connaître du monde de la cryptomonnaie.",
  tran_his_tb_fiat_price: "Prix en monnaie fiduciaire",
  tran_his_tb_crypto_price: "Prix en cryptomonnaie",
  date: "Date",
  status: "Statut",
  details: "Détails",
  createdDate: "Date de création",
  transactionDetails: "Détails de la transaction",
  paymentCompleted: "Paiement terminé",
  paymentCompleted_visitDetail:
    "Votre paiement a été effectué avec succès. Veuillez consulter la page des détails de la transaction pour plus d'informations.",
  completed: "Terminé",
  pm_YOUPAY: "VOUS PAYEZ",
  pm_YOUGET: "VOUS RECEVEZ",
  pm_feeDescription: "c'est tout ce que vous payez, frais inclus",
  pm_bFee: "Frais Bitmambo",
  pm_otherFee: "Autres frais",
  pm_totalFee: "Frais totaux",
  btn_show: "Afficher",
  btn_hide: "Masquer",
  noti_kycError: "La vérification KYC doit être effectuée",
  wl_enterYour: "Entrez votre",
  wl_wAddress: "adresse de portefeuille*",
  wl_placeholder_enterWallet: "Entrez l'adresse de portefeuille",
  pm_paymentMethod: "Méthode de paiement",
  pm_select: "Sélectionnez une méthode de paiement*",
  pm_cardPayment: "Paiement par carte",
  pm_enterCard: "Entrez les informations de votre carte*",
  pm_billingWithCard: "Adresse de facturation associée à cette carte*",
  pm_saveCard: "Enregistrer ma carte pour les paiements futurs",
  pm_submit: "Soumettre le paiement",
  pm_submitting: "Soumission du paiement en cours",
  pm_billingWithPhone:
    "Adresse de facturation associée à ce numéro de téléphone*",
  nt_spendMustBeGta: "La dépense doit être supérieure à",
  nt_andSml: "et inférieure à"
}
export const svCommon = {
  transactionHistory: "Transaktionshistorik",
  transactionid: "Transaktions-ID",
  address: "Adress",
  home: "Start",
  BuyCrypto: "Köp Crypto",
  YouSpend: "Du betalar",
  YouReceive: "Du får",
  YouWillReceive: "Du kommer få",
  SelectCurrency: "Välj Valuta",
  SelectCrypto: "Välj Cryptovaluta",
  Continue: "Fortsätt",
  WelcomeToBitmambo: "Välkommen till Bitmambo",
  EnterYourEmail: "Ange din email...",
  ClickHereToRegisterABitmamboAccountNow:
    "Klicka här för att registrera ett konto",
  IAgreeBitmambo: "Jag godkänner Bitmambo",
  TermsOfUse: "Användervillkor",
  ByEnteringOTPYouAgreeWithTermsAndPrivacyPolicy:
    "Genom att ange engångslösenordet godkänner användarvillkor och integritetspolicy",
  WalletAddress: "Cryptoadress",
  Wallet: "Wallet",
  Register: "Registrera",
  Login: "Logga in",
  Newsletter: "Nyhetsbrev",
  Company: "Företag",
  Support: "Support",
  Request: "Request",
  FAQ: "FAQ",
  AboutUs: "Om oss",
  WeAcceptFollowingPaymentSystems: "We accepterar följande betalningsmetoder",
  YourRegistrationHasBeenSuccessful: "Your registrering lyckades ",
  YouArePaying: "Du betalar",
  ForThePriceOf: "för summan av",
  Your: "",
  WillBeSentTo: "kommer att skickas till",
  RemainingTime: "Ytterligare tid",
  PaymentHasExpired: "Prova igen",
  ReviewBillingAddress: "Granska fakturadress",
  MobilePayment: "Mobile Payment",
  PaymentDetails: "Betalningsdetaljer",
  phoneNumber: "Mobilnummer",
  email: "Email",
  firstName: "Förnamn",
  lastName: "Efternamn",
  identifyType: "Identitetstyp",
  identifyNumber: "Identitetsnummer",
  RegisterAccount: "Registera konto",
  selectCountry: "Välj land",
  description: "Beskrivning",
  requestSupport: "Request Support",
  enterYourFirstName: "Fyll i ditt förnamn...",
  enterYourLastName: "Fyll i ditt efternamn...",
  dayOfBirth: "Födelsedatum",
  enterYourPhoneNumber: "Fyll i ditt mobilnummer...",
  country: "Land",
  city: "Stad",
  state: "Region",
  zipcode: "Postkod",
  street: "Gatuadress",
  identityType: "Identitetstyp",
  identityNumber: "Identitetsnummer",
  update: "Uppdatera",
  information: "information",
  YourProfileHasBeenUpdated: "Din profil har uppdaterats",
  from: "Från",
  to: "Mottagare",
  currency: "Valuta",
  amount: "Summa",
  voucher: "Voucher",
  fees: "Avgift",
  serviceFee: "Serviceavgift",
  networkFee: "Nätverksavgift",
  token: "Token",
  network: "Nätverk",
  ERROR: "Fel",
  INIT: "Init",
  CREATED: "Created",
  PENDING: "Pending",
  PROCESSING: "Processing",
  COMPLETED: "Completed",
  DONE: "Done",
  remainingTime: "Återstående tid",
  payment: "Betalning",
  instructions: "Instruktioner",
  paymentSuccess: "Betalning lyckades",
  plsSelectSubOption: "Vänligen välj betalningsalternativ",
  incorrectAddress: "Felaktig adress",
  transactionFee: "Transaktionsavgift",
  spTooLow: "Summan är för liten",
  selectPaymentOption: "Välj betlaningsmetod",
  cardPayment: "Kortbetalning",
  billingAddress: "Fakturaadress för detta kortet",
  saveCard: "Spara mitt kort för framtida betalningar",
  cardNotice:
    "Dit kort sparas säkert för ditt konto. Du kommer bara behöve ange CVV/CVC-kod för framtida betalningar",
  bankTransfer: "Banköverföring",
  fetchingPaymentOption: "Laddar betalningsmetoder",
  waitingForPayment: "Väntar på att betalningen genomförs",
  kycNotify:
    "Användaren är inte verifierad. Vänligen gå igenom följande verifieringssteg.",
  SelectLanguage: "Välj språk",
  yourCard: "Ditt kort",
  cardHolderName: "Kortinnehavarens namn",
  cardNumber: "Kortnummer",
  expirationDate: "utgångsdatum",
  removeThisCard: "ta bort detta kort",
  d_instantBuy:
    "Köp USDT direkt med ditt kredit- eller betalkort hos Bitmambo!",
  d_instantTransaction: "Omedelbar transaktion",
  d_support: "Stöd",
  d_multiPaymentOptions: "Flera betalningsalternativ",
  d_gateToUSDT:
    "Din inkörsport till USDT, som underlättar omedelbara köp med säkerhet på toppnivå Njut av provisionssatser i världsklass!",
  d_payHoweverULike: "Betala hur du vill",
  d_homeBannerDes: `Göra BTC Investment säker och snabb | Viktiga nyheter om senaste
  utveckling i kryptovärlden | Detta är ännu en rubrik
  information om digitala valutor. | Göra BTC Investment säker
  och snabbt | Viktiga nyheter om senaste utvecklingen i kryptovärlden |
  Detta är ytterligare en rubrik som ger information om digitala valutor.`,
  d_SEAMLESSPROCESS: "SÖMLÖS PROCESS",
  d_howToPurchase: "Hur man köper Crypto från Bitmambo",
  d_samlessDescription: `Ge dig ut på en sömlös resa till en värld av digitala valutor med
  Bitmambo! Följ dessa enkla steg för att skaffa din
  föredragen kryptovaluta:`,
  d_specifyPurchaseAmount: "Ange ditt köpbelopp",
  d_enterAmount: "Ange önskat belopp med live-kalkylator",
  d_completeProfileVer: "Slutför din profilverifiering",
  d_enterWallet: "Ange din kryptoplånboksadress",
  d_payAndReceiveInstantly: "Betala och ta emot krypto direkt",
  d_buildYourPortfolio: "Bygg upp din portfölj",
  buyNow: "Köp nu",
  pay: "Betala",
  get: "Få",
  d_PAYMENTMETHOD: "BETALNINGSMETODER",
  faq_cryptocurrencies: "Vilka kryptovalutor kan jag köpa på Bitmambo?",
  faq_paymentMethods: "Vilka betalningsmetoder accepterar Bitmambo?",
  faq_isSafe: "Är det säkert att använda Bitmambo?",
  faq_verifyAccount: "Hur verifierar jag mitt konto?",
  faq_fee: "Vilka avgifter är förknippade med handel på Bitmambo?",
  faq_transactionTime: "Hur lång tid tar det att behandla en transaktion?",
  faq_res_paymentMethods:
    "Bitmambo stödjer kortbetalning, mobilbetalning och banköverföring",
  faq_res_fee:
    "Ja, du kan prova oss gratis i 30 dagar. Om du vill kan vi erbjuda dig ett gratis personligt 30-minuters onboarding-samtal för att komma igång så snabbt som möjligt.",
  ft_jumpStart: "Kickstarta din kryptoportfölj",
  ft_jumpStartDes1:
    "Bitmambo är det enklaste stället att köpa och sälja kryptovalutor.",
  ft_jumpStartDes2: "Registrera dig och kom igång idag.",
  ft_about: "Om Bitmambo",
  getStarted: "Kom igång",
  purchaseNow: "Köp nu",
  blogs: "Bloggar",
  transactions: "Transaktioner",
  signIn: "Logga in",
  blogs_description:
    "Några viktiga historier som du bör känna till från kryptovärlden.",
  tran_his_tb_fiat_price: "Fiatpris",
  tran_his_tb_crypto_price: "Kryptovalutapris",
  date: "Datum",
  status: "Status",
  details: "Detaljer",
  createdDate: "Skapat datum",
  transactionDetails: "Transaktionsdetaljer",
  paymentCompleted: "Betalning slutförd",
  paymentCompleted_visitDetail:
    "Din betalning har genomförts framgångsrikt. Besök transaktionsdetaljsidan för mer information.",
  completed: "Slutförd",
  pm_YOUPAY: "DU BETALAR",
  pm_YOUGET: "DU FÅR",
  pm_feeDescription: "är allt du betalar, avgifter inkluderade",
  pm_bFee: "Bitmambos avgift",
  pm_otherFee: "Andra avgifter",
  pm_totalFee: "Totala avgifter",
  btn_show: "Visa",
  btn_hide: "Dölj",
  noti_kycError: "KYC måste verifieras",
  wl_enterYour: "Ange din",
  wl_wAddress: "plånboksadress*",
  wl_placeholder_enterWallet: "Ange plånboksadress",
  pm_paymentMethod: "Betalningsmetod",
  pm_select: "Välj betalningsmetod*",
  pm_cardPayment: "Kortbetalning",
  pm_enterCard: "Ange kortinformation*",
  pm_billingWithCard: "Faktureringsadress kopplad till detta kort*",
  pm_saveCard: "Spara mitt kort för framtida betalningar",
  pm_submit: "Skicka betalning",
  pm_submitting: "Skickar betalning",
  pm_billingWithPhone: "Faktureringsadress kopplad till detta telefonnummer*",
  nt_spendMustBeGta: "Utgiften måste vara större än",
  nt_andSml: "och mindre än"
}
