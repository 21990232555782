/* eslint-disable no-unused-vars */
export enum APIPath {
  EMAIL_IDENTIFICATION = "/user/lite/email-identification",
  LOGIN = "/user/lite/login",
  REFRESH_TOKEN = "/user/lite/refresh",
  LOGIN_CONFIRM = "/user/lite/login/confirm",
  GET_PROFILE = "/user/profile/full/",
  GET_FEE = "/currency/conversion/get-conversion",
  CREATE_ACCOUNT = "/user/lite/create-account",
  GET_PAIRS_CURRENCY = "/currency/assets/pairs",
  VERIFY_ADDRESS = "/currency/address/verify",
  PREPARE_PAYMENT = "/currency/payment/prepare",
  PAYMENT = "/currency/payment/submit",
  GET_TRANSACTION_HISTORIES = "/currency/payment/history",
  CONFIRM = "/currency/payment/confirm",
  GET_TRANSACTION_DETAILS = "/currency/payment",
  GET_SSE_TRANSACTION_STATUS = "/currency/payment/status",

  // KYC
  GET_KYC_STATUS = "/user/profile/kyc/status",
  GET_KYC_URL = "/user/profile/kyc/create",

  // SAVE CARD
  SAVE_CARD = "/user/card",
  GET_CARDS = "/user/card",
  GET_CARD_DETAIL = "/user/card/detail",
  DELETE_CARD = "/user/card",

  // SUPPORT
  REQUEST_SUPPORT = "/user/faq/",

  // COUNTRY
  GET_COUNTRY = "/currency/current-country",

  // SETTINGS
  GET_SETTINGS = "/settings/versions",
  UPDATE_ACCOUNT = "user/lite/update-account"
}
