import Header from "../layouts/Header"
import Footer from "../layouts/Footer"

const withLayout = (WrappedComponent) => {
  return (props) => (
    <>
      <Header />
      <WrappedComponent {...props} />
      <Footer />
    </>
  )
}

export default withLayout
