import React, { useState, useEffect } from "react"
import * as yup from "yup"
import _ from "lodash"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import OutlinedInput from "@mui/material/OutlinedInput"
import InputAdornment from "@mui/material/InputAdornment"
import { toastWarning } from "src/utils/toast"
import { getCountry } from "src/utils/localStorage"
import {
  usePreparePayment,
  useVerifyWalletAddress
} from "src/hooks/queries/useCurrency"
import { useGetKycStatus } from "src/hooks/queries/useKyc"
import "./index.css"
import { useBuyCurrencyStore } from "src/stores/buyCurrency"
import { useSettingsStore } from "src/stores/settings"
import { handleError } from "src/utils/toast"
import { useTranslation } from 'react-i18next';
import WalletIcon from "@mui/icons-material/Wallet"
import { LoadingButton } from '@mui/lab';
import ModalKyc from './ModalKyc';

const formSchema = yup.object().shape({
  address: yup.string().required("Required.")
})

const WalletForm = () => {
  const { t } = useTranslation()
  const [isOpenKycModal, setIsOpenKycModal] = useState(false)
  const country = getCountry()
  const {
    setFormState,
    onNext,
    transactionState: { from_currency, to_currency, network, spend }
  } = useBuyCurrencyStore()
  const { kyc } = useSettingsStore()

  const { mutateAsync: mutateVerifyWalletAddress, isLoading } =
    useVerifyWalletAddress()
  const { mutate: mutatePreparePayment } = usePreparePayment()
  const { data: kycStatusData, refetch: refetchKycStatus } = useGetKycStatus()

  const { handleSubmit, register, formState } = useForm({
    resolver: yupResolver(formSchema),
    mode: "all"
  })

  const handleSubmitForm = async ({ address }) => {
    const res = await mutateVerifyWalletAddress({
      address,
      network: network
    })

    if (res.isValid) {
      setFormState({ walletAddress: address })
      mutatePreparePayment(
        {
          from_currency: from_currency,
          to_currency: to_currency,
          network: network,
          address,
          amount: spend,
          country: country
        },
        {
          onSuccess: (data) => {
            onNext()
          },
          onError: (error) => {
            if (
              error?.response.status === 412 && kycStatusData?.status == false
            ) {
              toastWarning({
                description: t("noti_kycError")
              })
              return new Promise((resolve) => {
                setTimeout(() => {
                  resolve(setIsOpenKycModal(true))
                }, 1000)
              })
            } else {
              handleError(error)
            }
          }
        }
      )
    }
  }

  const handleCloseKycModal = () => {
    setIsOpenKycModal(false)
    refetchKycStatus()
  }
  useEffect(() => {
    const interval = setInterval(() => {
      refetchKycStatus()
    }, 3000)

    return () => clearInterval(interval)
  }, [])
  useEffect(() => {
    if (kycStatusData?.status == true) {
      setIsOpenKycModal(false)
    }
  }, [kycStatusData?.status])
  return (
    <div className="wallet__container">
      <ModalKyc open={isOpenKycModal} onClose={handleCloseKycModal} />
      <div className="wallet-header">
        <p>{t("Wallet")}</p>
      </div>
      <div className="wallet__content">
        <div className="wallet__inner">
          <p className="description">
            {t("wl_enterYour")} {to_currency} {t("wl_wAddress")}
          </p>
          <form
            onSubmit={handleSubmit(handleSubmitForm)}
            style={{ width: "100%" }}
          >
            <OutlinedInput
              className="mail-input"
              placeholder={t("wl_placeholder_enterWallet")}
              {...register("address")}
              startAdornment={
                <InputAdornment position="start">
                  <WalletIcon />
                </InputAdornment>
              }
            />
            <div className="continue-button__content">
              <LoadingButton
                variant="contained"
                className="loading-button"
                type="submit"
                loading={isLoading}
              >
                {t("Continue")}
              </LoadingButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default WalletForm
