import InputBase from "@mui/material/InputBase"
import IconButton from "@mui/material/IconButton"
import SearchIcon from "@mui/icons-material/Search"

import "./index.css"

const SearchBox = ({ rest }) => {
  return (
    <div className="search-box" {...rest}>
      <div className="header">
        <p>SEARCH</p>
      </div>
      <div className="search__content">
        <div className="button__content">
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
          <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Search....." />
        </div>
      </div>
    </div>
  )
}
export default SearchBox
