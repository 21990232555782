export const footerContent = [
  {
    title: "Company",
    detail: [
      { title: "About", link: "#" },
      { title: "Features", link: "#" },
      { title: "Works", link: "#" },
      { title: "Career", link: "#" }
    ]
  },
  {
    title: "Help",
    detail: [
      { title: "Customer Support", link: "#" },
      { title: "Delivery Details", link: "#" },
      { title: "Terms & Conditions", link: "#" },
      { title: "Privacy Policy", link: "#" }
    ]
  },
  {
    title: "Resources",
    detail: [
      { title: "Free eBooks", link: "#" },
      { title: "Development Tutorial", link: "#" },
      { title: "How to - Blog", link: "#" },
      { title: "Youtube Playlist", link: "#" }
    ]
  }
]
