import { map } from "lodash-es"
import { useTranslation } from "react-i18next"

import BlogBox from "../../components/BlogBox"
import withLayout from "../../hoc/withLayout"
import { BLOG_DATA } from "./content"

import "./index.css"

const Blog = () => {
  const { t } = useTranslation()
  return (
    <div className="blog__container">
      <div className="blog__inner">
        <div className="title__content">
          <p className="title">{t('blogs')}</p>
          <div className="break" />
          <p className="description">
            {t('blogs_description')}
          </p>
        </div>
        <div className="blog__content">
          {map(BLOG_DATA, (data) => (
            <BlogBox data={data} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default withLayout(Blog)
