export const LASTED_BLOG_DATA = [
  {
    title: "Security Meets Convenience: Why Mobile",
    url: "/images/blog/1.png"
  },
  {
    title: "Security Meets Convenience: Why Mobile",
    url: "/images/blog/1.png"
  },
  {
    title: "Security Meets Convenience: Why Mobile",
    url: "/images/blog/1.png"
  },
  { title: "Security Meets Convenience: Why Mobile", url: "/images/blog/1.png" }
]
