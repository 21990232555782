import withLayout from "../../hoc/withLayout"
import RegisterForm from "../../components/Register"


const Register = () => {
  return (
    <div className="authentication__container">
      <div className="authentication__inner">
        <RegisterForm/>
      </div>
    </div>
  )
}

export default withLayout(Register)
