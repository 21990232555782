import React, { useRef } from "react"
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Slide
} from "@mui/material"
import { useGetKycUrl } from "src/hooks/queries/useKyc"

const Transition = React.forwardRef(
  (
    props,
    ref
  ) => <Slide direction='up' ref={ref} {...props} />
);
const ModalKyc = (props) => {
  const { open } = props
  const iframeRef = useRef(null)
  const { data, isLoading } = useGetKycUrl({ enabled: open })
  return (
    <Dialog
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: { borderRadius: "20px", p: 2 }
      }}
      {...props}
    >
      <DialogTitle variant="button" textAlign="center">
        Basic verification
      </DialogTitle>
      <DialogContent sx={{ borderRadius: "20px" }}>
        {isLoading && <LinearProgress />}
        <Box
          sx={{
            mt: 4,
            iframe: {
              width: "100%",
              height: "1000px",
              minHeight: 0,
              border: "none"
            }
          }}
        >
          <iframe
            id="myIframe"
            title="Inline Frame Example"
            ref={iframeRef}
            src={open ? data?.url : ""}
            onChange={(e) => console.log(e)}
          />
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default ModalKyc
