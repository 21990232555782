import Profile from "../../components/Profile"
import withLayout from "../../hoc/withLayout"
import "./index.css"

const ProfilePg = () => {
  return (
    <div className="profile__container">
      <Profile />
    </div>
  )
}

export default withLayout(ProfilePg)
