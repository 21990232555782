import { map } from "lodash-es"
import { MenuItem, Select } from "@mui/material"
import { COUNTRIES } from 'src/constants/country'
import './index.css'

const CountrySelect = ({ currencies, value, onChange, ...rest }) => {
  return (
    <Select
      className="coin-select"
      value={value}
      onChange={onChange}
      displayEmpty
      inputProps={{ "aria-label": "Without label" }}
      size="small"
      {...rest}
    >
      {map(COUNTRIES, ({ id, label }) => {
        return (
          <MenuItem value={id} className="coin__container" key={id}>
            <div className="select-input">
              <p >{label}</p>
            </div>
          </MenuItem>
        )
      })}
    </Select>
  )
}

export default CountrySelect
