import { useState } from "react"
import DropdownMenu from "../Dropdown"
import Logo from "../Logo"

import "./index.css"
import { Button } from "@mui/base"
import CurrencySelect from "../CurrencySelect"
import { useBuyCurrencyStore } from 'src/stores/buyCurrency';
import { formatPrice, calServiceFee } from "src/utils/number"
import {formatCurrency} from "src/utils/string"
import { useTranslation } from 'react-i18next';


const ConvertForm = () => {
  const { t } = useTranslation()
  const [isHide, setHide] = useState(false)
  const {
    transactionState: {
      price,
      receive,
      spend,
      from_currency,
      to_currency,
      serviceFee,
      networkFee,
    },
  } = useBuyCurrencyStore();

  return (
    <div className="convert-from__container">
      <div className="convert-from__inner">
        <div className="convert-from-header">
          <Logo />
        </div>

        <div className="convert-from__content">
          <div className="convert-select__content">
            <p className="convert-type">{t('pm_YOUPAY')}</p>
            <div className="select__content">
              <CurrencySelect className="dropdown-box" value={from_currency} currencies={[from_currency]} disabled/>
              <p className="convert-currency">{formatPrice(spend)}</p>
            </div>
          </div>

          <div className="convert-select__content">
            <p className="convert-type">{t('pm_YOUGET')}</p>
            <div className="select__content">
              <CurrencySelect className="dropdown-box"  value={to_currency} currencies={[to_currency]} disabled/>
              <p className="convert-currency">{formatPrice(receive)}</p>
            </div>
          </div>

          <p className="amout-change">1 {to_currency} ≈ {formatPrice(price)} {from_currency}</p>

          <div className="fee__content">
            <div className="fee-line">
              <div className="all-pay">
                <p className="currency">{formatPrice(spend)} {from_currency} </p>
                <p className="description">{t('pm_feeDescription')}</p>
              </div>
              <Button onClick={() => setHide(!isHide)} className="hide-button">
                {isHide ? t("btn_hide") : t("btn_show")}
              </Button>
            </div>
            <div className="break" />
            {isHide && (
              <>
                <div className="fee-line">
                  <p>{t('pm_bFee')}</p>
                  <p>{formatCurrency(calServiceFee(serviceFee, spend), from_currency)}</p>
                </div>

                <div className="fee-line">
                  <p>{t('pm_otherFee')}</p>
                  <p>{formatCurrency(networkFee, from_currency)}</p>
                </div>
                <div className="break" />

                <div className="fee-line">
                  <p>{t('pm_totalFee')}</p>
                  <p>{formatCurrency(calServiceFee(serviceFee, spend) + networkFee, from_currency)}</p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConvertForm
