export const BLOG_DATA = [
  {
    url: "/images/blog/1.png",
    type: "BILLERS",
    title:
      "Security Meets Convenience: Why Mobile Bill Pay Can Become a Key Defense Against Fraud",
    description:
      "The adoption of digital wallets has been growing around the world. Today in the U.S., digital wallets such as PayPal, Venmo and Square Cash are widely used alongside smartphone-native solutions like Apple Pay and Google Pay."
  },
  {
    url: "/images/blog/2.png",
    type: "BANKING",
    title:
      "Next-Generation Payments in Aotearoa, New Zealand: A new dawn for growth",
    description:
      "The relatively small scale of the Aotearoa market may discourage a high level of capital investment in payments infrastructure seen in markets such as Australia. Yet that shouldn’t be an obstacle to creating a highly resilient, agile, secure, extensible and scalable next-generation payments system."
  },
  {
    url: "/images/blog/3.png",
    type: "MARCHANTS",
    title: "6 Signs Reconciliation Inefficiencies Are Burdening Your Business",
    description:
      "In the battle to win sales in a market that’s increasingly shaped by intense competition, rapid change and economic disruptors, the customer experience is now king. With much of a merchant’s focus on front end functionality, back-end processes like payments and account reconciliation are often inadvertently relegated to the back seat."
  },
  {
    url: "/images/blog/1.png",
    type: "BILLERS",
    title:
      "Security Meets Convenience: Why Mobile Bill Pay Can Become a Key Defense Against Fraud",
    description:
      "The adoption of digital wallets has been growing around the world. Today in the U.S., digital wallets such as PayPal, Venmo and Square Cash are widely used alongside smartphone-native solutions like Apple Pay and Google Pay."
  },
  {
    url: "/images/blog/2.png",
    type: "BANKING",
    title:
      "Next-Generation Payments in Aotearoa, New Zealand: A new dawn for growth",
    description:
      "The relatively small scale of the Aotearoa market may discourage a high level of capital investment in payments infrastructure seen in markets such as Australia. Yet that shouldn’t be an obstacle to creating a highly resilient, agile, secure, extensible and scalable next-generation payments system."
  },
  {
    url: "/images/blog/3.png",
    type: "MARCHANTS",
    title: "6 Signs Reconciliation Inefficiencies Are Burdening Your Business",
    description:
      "In the battle to win sales in a market that’s increasingly shaped by intense competition, rapid change and economic disruptors, the customer experience is now king. With much of a merchant’s focus on front end functionality, back-end processes like payments and account reconciliation are often inadvertently relegated to the back seat."
  },
  {
    url: "/images/blog/1.png",
    type: "BILLERS",
    title:
      "Security Meets Convenience: Why Mobile Bill Pay Can Become a Key Defense Against Fraud",
    description:
      "The adoption of digital wallets has been growing around the world. Today in the U.S., digital wallets such as PayPal, Venmo and Square Cash are widely used alongside smartphone-native solutions like Apple Pay and Google Pay."
  },
  {
    url: "/images/blog/2.png",
    type: "BANKING",
    title:
      "Next-Generation Payments in Aotearoa, New Zealand: A new dawn for growth",
    description:
      "The relatively small scale of the Aotearoa market may discourage a high level of capital investment in payments infrastructure seen in markets such as Australia. Yet that shouldn’t be an obstacle to creating a highly resilient, agile, secure, extensible and scalable next-generation payments system."
  },
  {
    url: "/images/blog/3.png",
    type: "MARCHANTS",
    title: "6 Signs Reconciliation Inefficiencies Are Burdening Your Business",
    description:
      "In the battle to win sales in a market that’s increasingly shaped by intense competition, rapid change and economic disruptors, the customer experience is now king. With much of a merchant’s focus on front end functionality, back-end processes like payments and account reconciliation are often inadvertently relegated to the back seat."
  },
  {
    url: "/images/blog/1.png",
    type: "BILLERS",
    title:
      "Security Meets Convenience: Why Mobile Bill Pay Can Become a Key Defense Against Fraud",
    description:
      "The adoption of digital wallets has been growing around the world. Today in the U.S., digital wallets such as PayPal, Venmo and Square Cash are widely used alongside smartphone-native solutions like Apple Pay and Google Pay."
  },
  {
    url: "/images/blog/2.png",
    type: "BANKING",
    title:
      "Next-Generation Payments in Aotearoa, New Zealand: A new dawn for growth",
    description:
      "The relatively small scale of the Aotearoa market may discourage a high level of capital investment in payments infrastructure seen in markets such as Australia. Yet that shouldn’t be an obstacle to creating a highly resilient, agile, secure, extensible and scalable next-generation payments system."
  },
  {
    url: "/images/blog/3.png",
    type: "MARCHANTS",
    title: "6 Signs Reconciliation Inefficiencies Are Burdening Your Business",
    description:
      "In the battle to win sales in a market that’s increasingly shaped by intense competition, rapid change and economic disruptors, the customer experience is now king. With much of a merchant’s focus on front end functionality, back-end processes like payments and account reconciliation are often inadvertently relegated to the back seat."
  }
]
