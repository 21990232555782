export enum RoutePath {
  LOGIN = `login`,
  HOME = `/`,
  BLOGS = `/blogs`,
  BLOG_DETAIL = `/blogs/:id`,
  CONTACT = `/contact`,
  REGISTER = `/register`,
  TRANSACTION_HISTORY = `/transactions`,
  PROFILE = `/profile`,
  PRIVACY = `/privacy`,
  ABOUT_US = `/about-us`,
  FAQ = `/faq`,
  REQUEST = `/request`,
  COOKIE_POLICY = `/cookie-policy`,
  USER_AGREEMENT = `/user-agreement`,
  TRANSACTION_DETAILS = `/transactions/:id`,
  REF = `/referral/:id`,
  KYC_COMPLETED = `/kyc-completed`,
  PAYMENT = `/payment`,
  TRANSACTION_COMPLETED = `/transaction-completed`
}
