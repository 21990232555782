import React, { useEffect,useState } from 'react';
import { Box, Stack, CircularProgress, Typography } from '@mui/material';

import _ from 'lodash';

import { useBuyCurrencyStore } from 'src/stores/buyCurrency';
import { usePayment } from 'src/hooks/queries/useCurrency';
import ModalRedirect from './ModalRedirect'
import { useTranslation } from 'react-i18next';

const BankTransfer = () => {
  const { i18n, t } = useTranslation();
  const [openModal, setOpen] = useState(false);
  
  // stores
  const {
    transactionState: { id },
    onNext
  } = useBuyCurrencyStore();

  // hooks
  const { mutateAsync: payment, isLoading } = usePayment();

  const submitPayment = async () => {
    await payment({
      payment_id: id,
      payment_option: 'Bank Transfer',
      country: 'FR',
      state: 'Temporary address',
      address: 'Temporary address',
      city: 'Temporary address',
      zip: '500000',
      card_holder_name: '',
      card_number: '',
      expiration_date: '',
      cardSaveNumber: '',
      cvv: '',
    },
    {
      onSuccess(data) {
        setOpen(true);
      },
    });
  };

  useEffect(() => {
    if (id) {
      submitPayment();
    }
  }, []);

  const closeModal = () => {
    setOpen(false);
    onNext()
  };
  return (
    <div className="wallet__container">
      <div className="wallet-header">
        <p>{t("bankTransfer")}</p>
      </div>
      <div className="wallet__content">
        <div className="wallet__inner">
        <ModalRedirect open={openModal} handleClose={closeModal} />
        <Stack spacing={2} mt={4}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <CircularProgress />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Typography>{t("pm_submitting")}</Typography>
          </Box>
        </Stack>
          
        </div>
      </div>
    </div>
  );
};

export default BankTransfer;
