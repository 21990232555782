import React, { useEffect } from 'react';
import { Box, Stack, Select, MenuItem } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';

import OutlinedInput from "@mui/material/OutlinedInput"

import { useGetProfile, useUpdateProfile } from 'src/hooks/queries/useAuth';
import { useTranslation } from 'react-i18next';
import { COUNTRIES } from 'src/constants/country';
import { useSettingsStore } from 'src/stores/settings';
import "./index.css"
import { LoadingButton } from '@mui/lab';

const Information = () => {
    const { authenticationMethod } = useSettingsStore();
    const { t } = useTranslation();
    const isPhoneEditable = authenticationMethod == 'phone';
    const isEmailEditable = authenticationMethod == 'email';

    const { register, reset, handleSubmit, control } = useForm({
        defaultValues: { dayofbirth: null },
    });
    const { data } = useGetProfile();
    const { mutate, isLoading } = useUpdateProfile();

    const handleSubmitForm = (values) => {
        mutate(values);
    };

    useEffect(() => {
        if (data) {
            reset(data);
        }
    }, [data]);

    return (
        <form onSubmit={handleSubmit(handleSubmitForm)}>
            <Stack spacing={2}>
                <OutlinedInput
                    className="profile-input"
                    placeholder={t('EnterYourEmail')}
                    readOnly={!isEmailEditable}
                    {...register('email')}
                />
                <Box
                    display='flex'
                    gap={{
                        base: 2,
                        xs: 1,
                        sm: 1,
                        md: 2,
                    }}
                >
                    <OutlinedInput
                        className="profile-input"
                        placeholder={t('enterYourFirstName')}
                        {...register('first_name')}
                    />
                    <OutlinedInput
                        className="profile-input"
                        placeholder={t('enterYourLastName')}
                        {...register('last_name')}
                    />
                </Box>
                <Box
                    display='flex'
                    gap={{
                        base: 2,
                        xs: 1,
                        sm: 1,
                        md: 2,
                    }}
                >
                    <OutlinedInput {...register('dayofbirth')} placeholder={t('dayofbirth')} className="profile-input" />
                    <OutlinedInput
                        className="profile-input"
                        placeholder={t('enterYourPhoneNumber')}
                        {...register('phone')}
                        readOnly={!isPhoneEditable}
                    />
                </Box>
                <Box
                    display='flex'
                    gap={{
                        base: 2,
                        xs: 1,
                        sm: 1,
                        md: 2,
                    }}
                >
                    <Controller
                        name='country.country_short'
                        control={control}
                        defaultValue=''
                        render={({ field }) => (
                            <Select
                                className="profile-input"
                                displayEmpty
                                {...field}
                            >
                                {COUNTRIES.map(({
                                    value,
                                    label,
                                    id,
                                }) => (
                                    <MenuItem value={value} key={id}>
                                        {label}
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                    />

                    <OutlinedInput
                        {...register('city')}
                        placeholder={t('city')}
                        className="profile-input" />
                </Box>
                <Box
                    display='flex'
                    gap={{
                        base: 2,
                        xs: 1,
                        sm: 1,
                        md: 2,
                    }}
                >
                    <OutlinedInput
                        className="profile-input"
                        placeholder={t('state')}
                        {...register('state')}
                    />
                    <OutlinedInput
                        className="profile-input"
                        placeholder={t('zipcode')}
                        type='number'
                        {...register('postal_code')}
                    />
                </Box>
                <OutlinedInput
                    className="profile-input"
                    placeholder={t('street')}
                    {...register('street1')}
                />

                <LoadingButton
                    variant='contained'
                    type='submit'
                    disabled={false}
                    fullWidth
                    loading={isLoading}
                >
                    {t('update')}
                </LoadingButton>
            </Stack>
        </form>

    );
};

export default Information;

