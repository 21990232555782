import { useMutation, useQuery } from "react-query"
import { useHistory } from "react-router"
import { APIPath } from "src/apis/api-path"
import { useTranslation } from "react-i18next"

import {
  createAccount,
  emailIdentification,
  getProfile,
  login,
  loginConfirm,
  refreshToken,
  updateAccount
} from "src/apis/auth"
import { RoutePath } from "src/routes/path"
import { useGlobalStore } from "src/stores"
import { setToken } from "src/utils/localStorage"
import { handleError, toastSuccess } from "src/utils/toast"

export const useLogin = () =>
  useMutation(login, {
    onSuccess: (message) => {
      toastSuccess({ description: message })
    }
  })

export const useLoginConfirm = () => {
  const { setUser, setIsAuth } = useGlobalStore()
  const history = useHistory()

  return useMutation(loginConfirm, {
    onError: (error) => handleError(error),
    onSuccess: (res) => {
      setUser({
        token: res.access_token,
        email: "",
        username: ""
      })
      setIsAuth(true)
      setToken(res.access_token)
    }
  })
}

export const useRefreshToken = () => {
  const { setUser, setIsAuth } = useGlobalStore()

  return useMutation(refreshToken, {
    onError: (error) => handleError(error),
    onSuccess: (res) => {
      setUser({
        token: res.access_token,
        email: "",
        username: ""
      })
      setIsAuth(true)
      setToken(res.access_token)
    }
  })
}

export const useEmailIdentification = () => useMutation(emailIdentification)

export const useCreateAccount = () => {
  const { i18n, t } = useTranslation()
  const history = useHistory()
  return useMutation(createAccount, {
    onError: (error) => handleError(error),
    onSuccess: () => {
      toastSuccess({ description: t("YourRegistrationHasBeenSuccessful") })
      // setTimeout(() => {
      //   history.push(RoutePath.LOGIN);
      // }, 1000);
    }
  })
}

export const useGetProfile = () => useQuery(APIPath.GET_PROFILE, getProfile)

export const useUpdateProfile = () => {
  const { i18n, t } = useTranslation()
  const history = useHistory()
  return useMutation(updateAccount, {
    onError: (error) => handleError(error),
    onSuccess: () => {
      toastSuccess({ description: t("YourProfileHasBeenUpdated") })
      // setTimeout(() => {
      //   history.push(RoutePath.PROFILE)
      // }, 1000)
    }
  })
}
