import withLayout from "../../hoc/withLayout"
import TransactionDetails from 'src/components/TransactionDetails'
import { useTranslation } from 'react-i18next';

const TransactionDetailsPage = () => {
  const { t } = useTranslation()

  return (
    <div className="transaction__container">
      <div className="transaction__inner">
        <div className="title__content">
          <p className="title">{t("transactionDetails")}</p>
          <div className="break" />
        </div>
        <div className="transaction-form__content">
            <TransactionDetails/>
        </div>
      </div>
    </div>
  )
}

export default withLayout(TransactionDetailsPage)
