import withLayout from "../../hoc/withLayout"
import LoginForm from "../../components/LoginForm"


const Login = () => {
  return (
    <div className="authentication__container">
      <div className="authentication__inner">
        <LoginForm redirect={true}/>
      </div>
    </div>
  )
}

export default withLayout(Login)
