import { useState } from "react"
import Drawer from "@mui/material/Drawer"
import { Link } from "@mui/material"
import { IconButton } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import Collapse from "@mui/material/Collapse"
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import i18n from 'i18next';
import { useGlobalStore } from 'src/stores';

import "./index.css"

const MobileMenu = ({ isOpen, setOpen }) => {
  const [openCollapse, setOpenCollapse] = useState("")
  const { isAuth } = useGlobalStore();

  return (
    <>
      <Drawer anchor="right" open={isOpen} onClose={() => setOpen(false)}>
        <div className="menu__inner">
          <div className="header__content">
            <p>Hello Nana</p>
            <IconButton className="close-button" onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className="menu__content">
            <Link underline="hover" href="#" className="link">
              Home
            </Link>
            <Link underline="hover" href={`/${i18n.language}/blogs`}>
              Blogs
            </Link>
            { isAuth && (
              <Link underline="hover" href={`/${i18n.language}/transactions`}>
                Transactions
              </Link>
            )}

            <>
              <div
                className="expand-button"
                onClick={() =>
                  setOpenCollapse(
                    openCollapse === "languages" ? "" : "languages"
                  )
                }
              >
                <p>English</p>
                {openCollapse === "languages" ? <ExpandLess /> : <ExpandMore />}
              </div>
              <Collapse
                in={openCollapse === "languages"}
                timeout="auto"
                unmountOnExit
              >
                <div className="languages-expand">
                  <p>Vietnamese</p>
                  <p>Singapore</p>
                </div>
              </Collapse>
            </>

            <>
              <div
                className="expand-button"
                onClick={() =>
                  setOpenCollapse(openCollapse === "tools" ? "" : "tools")
                }
              >
                <p>Tools</p>
                {openCollapse === "tools" ? <ExpandLess /> : <ExpandMore />}
              </div>

              <Collapse
                in={openCollapse === "tools"}
                timeout="auto"
                unmountOnExit
              >
                <div className="languages-expand">
                  <p>TBD</p>
                </div>
              </Collapse>
            </>
          </div>
        </div>
      </Drawer>
    </>
  )
}

export default MobileMenu
