import _ from "lodash"

export const formatPrice = (value: string | number, decimal?: number) =>
  _.round(Number(value), decimal || 2)

export const calServiceFee = (
  fee: string | number,
  amount: string | number
) => {
  const _fee = Number(fee)
  const _amount = Number(amount)

  return formatPrice((_amount / 100) * _fee)
}
