import React from 'react';

import { useSettingsStore } from 'src/stores/settings';
import RegisterWithEmail from './RegisterWithEmail';
import RegisterWithPhone from './RegisterWithPhone';

const RegisterForm = () => {
    const { authenticationMethod } = useSettingsStore();
    return authenticationMethod === 'email' ? (
      <RegisterWithEmail />
    ) : (
      <RegisterWithPhone />
    );
};

export default RegisterForm
