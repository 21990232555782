import { Currency } from "src/Types/Currency"
import { create } from "zustand"

export type FormState = {
  email?: string | null
  walletAddress?: string | null
}
type Instructions = {
  data: string
}

type SubOptions = {
  id: string
  name: string
  logo: string
  uuid: string
  formInputs: any[]
}
type TransactionState = {
  price?: number | null
  id?: string | null
  spend?: number | string | null
  receive?: number | string | null
  fiat?: string | null
  currency?: Currency
  currencies?: Currency[]
  networkFee?: number
  serviceFee?: number
  networkFeeInUSD?: number
  from_currency?: string | null
  to_currency?: string | null
  to_currency_id?: string | null
  network?: string | null
  subOptions?: SubOptions[] | null
  minPayment?: number
  maxPayment?: number
  instructions?: Instructions | null
  redirectUrl?: string | null
}
type SessionState = {
  firstPay?: boolean
}
export type BuyCurrencyState = {
  step: number
  formState: FormState
  transactionState: TransactionState
  sessionState: SessionState
  onNext: () => void
  setStep: (value: number) => void
  onBack: () => void
  setFormState: (values: FormState) => void
  setTransactionState: (values: TransactionState) => void
  setSessionState: (values: SessionState) => void
  resetFormState: () => void
  resetTransactionState: () => void
  resetStore: () => void
}

const initialState: BuyCurrencyState = {
  step: 1,
  onNext: () => null,
  onBack: () => null,
  setStep: () => null,
  formState: {
    email: null,
    walletAddress: null
  },
  transactionState: {
    price: 0,
    id: null,
    spend: 0,
    receive: 0,
    networkFee: 0,
    serviceFee: 0,
    from_currency: null,
    to_currency: null,
    to_currency_id: null,
    network: null,
    currencies: [],
    subOptions: [],
    minPayment: 0,
    maxPayment: 1000,
    networkFeeInUSD: 0,
    instructions: null,
    redirectUrl: null
  },
  sessionState: {
    firstPay: false
  },
  setFormState: () => null,
  setTransactionState: () => null,
  setSessionState: () => null,
  resetFormState: () => null,
  resetTransactionState: () => null,
  resetStore: () => null
}

export const useBuyCurrencyStore = create<BuyCurrencyState>((set, get) => ({
  ...initialState,
  setStep: (value: number) => set(({ step }) => ({ step: value })),
  onNext: () => set(({ step }) => ({ step: step + 1 })),
  onBack: () =>
    set(({ step }) => {
      if (step <= 2) {
        return { step: step - 1 }
      }
      get().resetStore()
      return { step: 1 }
    }),
  setFormState: (values: FormState) =>
    set(({ formState }) => ({
      formState: { ...formState, ...values }
    })),
  setTransactionState: (values: TransactionState) =>
    set(({ transactionState }) => ({
      transactionState: { ...transactionState, ...values }
    })),
  setSessionState: (values: SessionState) =>
    set(({ sessionState }) => ({
      sessionState: { ...sessionState, ...values }
    })),
  resetFormState: () =>
    set(() => ({ formState: { ...initialState.formState } })),
  resetTransactionState: () =>
    set(() => ({
      transactionState: { ...initialState.transactionState }
    })),
  resetStore: () =>
    set(({ transactionState }) => ({
      step: 1,
      formState: { ...initialState.formState },
      transactionState: {
        ...transactionState,
        id: null,
        spend: 0,
        receive: 0
      }
    }))
}))
