import Button from "@mui/material/Button"
import { useTranslation } from "react-i18next"

import "./index.css"

const SubFooter = () => {
  const { t } = useTranslation()

  return (
    <div className="sub-footer__container">
      <div className="sub-footer__inner">
        <div className="sub-footer__content">
          <img
            className="right-vector"
            src="/icons/vector/sub-footer-right.svg"
            alt=""
          />
          <p className="title">{t('ft_jumpStart')}</p>
          <div className="description__content">
            <p className="description">
            {t('ft_jumpStartDes1')}
            </p>
            <p className="description"> {t('ft_jumpStartDes2')}</p>
          </div>
        </div>
        <div className="group-button__container">
          <Button className="button started-button">{t('getStarted')}</Button>
          <Button variant="outlined" className="button purchase-button">
          {t('purchaseNow')}
          </Button>
        </div>
      </div>
    </div>
  )
}
export default SubFooter
