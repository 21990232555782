import OutlinedInput from "@mui/material/OutlinedInput"
import { TextareaAutosize } from "@mui/base/TextareaAutosize"
import Button from "@mui/material/Button"

import withLayout from "../../hoc/withLayout"

import "./index.css"

const Contact = () => {
  return (
    <div className="contact__container">
      <div className="contact__inner">
        <div className="left-vector" />
        <div className="title__content">
          <p className="title">Contact us</p>
          <div className="break" />
          <p className="description">Ask for help</p>
        </div>
        <div className="contact-form__content">
          <div className="input-group">
            <p className="label">First Name</p>
            <OutlinedInput className="input" />
          </div>
          <div className="input-group">
            <p className="label">Last Name</p>
            <OutlinedInput className="input" />
          </div>
          <div className="input-group">
            <p className="label">Email</p>
            <OutlinedInput className="input" />
          </div>
          <div className="input-group">
            <p className="label">Description</p>
            <TextareaAutosize className="text-area" minRows={5} maxRows={7} />
          </div>
        </div>
        <Button variant="contained" type="submit" className="submit-button">
          Submit
        </Button>
      </div>
    </div>
  )
}

export default withLayout(Contact)
