import React, { useState } from "react"

import { LoadingButton } from '@mui/lab';
import PhoneIcon from "@mui/icons-material/Phone"
import MailIcon from "@mui/icons-material/Mail"
import OutlinedInput from "@mui/material/OutlinedInput"
import InputAdornment from "@mui/material/InputAdornment"
import VerificationCodeInput from "../VerificationCodeInput"
import { useLoginConfirm } from "src/hooks/queries/useAuth"
import { useLoginStore } from "src/stores/login"
import { useBuyCurrencyStore } from "src/stores/buyCurrency"
import { useSettingsStore } from 'src/stores/settings';
import i18n from 'i18next';
import { useTranslation } from "react-i18next"

import "./index.css"

const VerifyLoginOtp = ({ redirect }) => {
  
  const { authenticationMethod } = useSettingsStore();
  const { onNext } = useBuyCurrencyStore()
  const { t } = useTranslation()

  const {
    formState: { email, phone }
  } = useLoginStore()
  const { mutate, isLoading } = useLoginConfirm()
  const [otp, setOtp] = useState("")

  const isDisabled = otp.length < 6

  const handleSubmit = async (event) => {
    event.preventDefault()
    mutate(
      { email: email, otp, phone: phone },
      {
        onSuccess: () => {
          if(redirect) {
            window.location.replace(`/${i18n.language}`)
          }
          onNext()
        }
      }
    )
  }

  return (
    <div className="verify__container">
      <div className="verify-header">
        <p>{t('VerifyEmail')}</p>
      </div>
      <div className="verify__content">
        <div className="verify__inner">
          <form onSubmit={handleSubmit}>
            <p className="description">
              {t('WeveSentAVerificationCodeToYourEmail')} <b>{email}</b>
            </p>
            { authenticationMethod === 'email' && (
              <OutlinedInput
                className="mail-input"
                value={email}
                readOnly
                startAdornment={
                  <InputAdornment position="start">
                    <MailIcon />
                  </InputAdornment>
                }
              />
            )}
            { authenticationMethod === 'phone' && (
              <OutlinedInput
                className="mail-input"
                value={phone}
                readOnly
                startAdornment={
                  <InputAdornment position="start">
                    <PhoneIcon />
                  </InputAdornment>
                }
              />
            )}
            <div>
              <VerificationCodeInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                isInputNum
              />
            </div>
            <div className="continue-button__content">
              <LoadingButton
                variant="contained"
                className="loading-button"
                type="submit"
                loading={isLoading}
                sx={{ marginTop: '1em'}}
                disabled={isDisabled}
              >
                {t('Continue')}
              </LoadingButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default VerifyLoginOtp
